import { NavLink, useParams } from 'react-router-dom';

const semiboldIfActive = ({isActive}: any) => {
  return {
    fontWeight: isActive ? 600 : 400,
  };
};

function Navbar2() {
  const { serverID: currentServerID } = useParams();

  return (
    <nav className="bg-body-tertiary d-none d-lg-block navbar navbar-expand-lg">
      <div className="container">
        <div>
          <ul className="mb-2 mb-lg-0 me-auto navbar-nav">
            <li className="nav-item">
              <NavLink className="me-1 nav-link ps-0" to={`${currentServerID}/overview`} style={semiboldIfActive}>Overview</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="me-1 nav-link" to={`${currentServerID}/analytics`} style={semiboldIfActive}>Analytics</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="disabled me-1 nav-link" to={`${currentServerID}/chat`} style={semiboldIfActive}>Chat</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="disabled me-1 nav-link" to={`${currentServerID}/chat`} style={semiboldIfActive}>Logs</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="disabled me-1 nav-link" to={`${currentServerID}/chat`} style={semiboldIfActive}>Operations</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="me-1 nav-link" to={`${currentServerID}/permissions`} style={semiboldIfActive}>Permissions</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="me-1 nav-link" to={`${currentServerID}/players`} style={semiboldIfActive}>Players</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="disabled me-1 nav-link" to={`${currentServerID}/plugins`} style={semiboldIfActive}>Plugins</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="me-1 nav-link" to={`${currentServerID}/settings`} style={semiboldIfActive}>Settings</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="me-1 nav-link" to={`${currentServerID}/whitelist`} style={semiboldIfActive}>Whitelist</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar2;

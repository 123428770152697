import React from 'react';
import Select from 'react-select';
import diamond from '../images/items/diamond.png';
import goldIngot from '../images/items/gold_ingot.png';
import ironIngot from '../images/items/iron_ingot.png';

interface Item {
  icon: string
  label: string
  value: string
}

function formatItemLabel(item: Item) {
  return (
    <div className="align-items-center d-flex">
      <img alt={item.label} className="me-2" height="24" src={item.icon} />
      <span>{item.label}</span>
    </div>
  );
}

interface User {
  label: string
  value: string
}

function formatUserLabel(user: User) {
  const shortUUID = user.value.split('-').slice(-1)[0];
  return (
    <div className="align-items-center d-flex">
      <img alt={user.label}
           className="me-2"
           height="20"
           src={`https://crafatar.com/renders/head/${user.value}?default=MHF_Steve&overlay`} />
      <span className="me-2">{user.label}</span>
      <div className="flex-grow-1"></div>
      <span className="text-secondary">
        <span style={{opacity: .1}}>{shortUUID.charAt(0)}</span>
        <span style={{opacity: .4}}>{shortUUID.charAt(1)}</span>
        <span style={{opacity: .7}}>{shortUUID.charAt(2)}</span>
        {shortUUID.slice(3)}
      </span>
    </div>
  );
}

const items: Item[] = [
  {icon: diamond, label: 'Diamond', value: 'diamond'},
  {icon: goldIngot, label: 'Gold Ingot', value: 'gold_ingot'},
  {icon: ironIngot, label: 'Iron Ingot', value: 'iron_ingot'},
];

const users: User[] = [
  {label: 'Notch', value: '069a79f4-44e9-4726-a5be-fca90e38aaf5'},
  {label: 'Yioh_', value: 'ee4a6d0e-e122-4a37-b4f3-c6e4ffed8ccf'},
  {label: 'devcexx', value: '5f0125e2-8bef-406f-8ab7-5b73c77fe98e'},
  {label: 'jrepollo', value: '56717b66-376a-4c2d-9579-8a5f6cb25c7c'},
  {label: 'ratnu', value: 'ba18ae46-6038-4b16-ac28-ab6402e3cfe9'},
];

function KitchenSink() {
  return (
    <div className="container mt-5">
      <div className="mb-4 row">
        <div className="col">
          <h1>Kitchen sink</h1>
        </div>
      </div>
      <div className="mb-4 row">
        <div className="col-12 col-md-6 col-lg-4">
          <Select defaultValue={items[0]} formatOptionLabel={formatItemLabel} options={items} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4">
          <Select defaultValue={users[0]} formatOptionLabel={formatUserLabel} options={users} />
        </div>
      </div>
    </div>
  );
}

export default KitchenSink;

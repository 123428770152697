import { useState } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import { MetricWithMetadata } from '../types/Statjar';
import { ReactComponent as ArrowRightCircle } from '../images/arrow-right-circle.svg';
import { aggregatesSizeMiB, format, measurementsSizeMiB } from './StatjarMetric';

export function formatAggregates(count: number): JSX.Element {
  const size = aggregatesSizeMiB(count);
  return (
    <span className="fw-medium">
      {format(count)}
      <span className="fw-normal ms-1">({size.toFixed(2)} MiB)</span>
    </span>
  );
}

export function formatMeasurements(count: number): JSX.Element {
  const size = measurementsSizeMiB(count);
  return (
    <span className="fw-medium">
      {format(count)}
      <span className="fw-normal ms-1">({size.toFixed(2)} MiB)</span>
    </span>
  );
}

function size(aggregatesCount: number, measurementsCount: number): string {
  const size = aggregatesSizeMiB(aggregatesCount) + measurementsSizeMiB(measurementsCount);
  return `${size.toFixed(2)} MiB`;
}

export default function StatjarMetrics(): JSX.Element {
  const metrics = useLoaderData() as MetricWithMetadata[];
  const [orderBy, setOrderBy] = useState('id');

  if (orderBy === 'id') {
    metrics.sort((a, b) => a.id - b.id);
  }

  if (orderBy === 'userID') {
    metrics.sort((a, b) => a.userID - b.userID);
  }

  if (orderBy === 'name') {
    metrics.sort((a, b) => a.name.localeCompare(b.name));
  }

  if (orderBy === 'aggregates') {
    metrics.sort((a, b) => b.aggregatesCount - a.aggregatesCount);
  }

  if (orderBy === 'measurements') {
    metrics.sort((a, b) => b.measurementsCount - a.measurementsCount);
  }

  if (orderBy === 'size') {
    metrics.sort((a, b) => {
      const aSize = aggregatesSizeMiB(a.aggregatesCount) + measurementsSizeMiB(a.measurementsCount);
      const bSize = aggregatesSizeMiB(b.aggregatesCount) + measurementsSizeMiB(b.measurementsCount);
      return bSize - aSize;
    });
  }

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li aria-current="page" className="active breadcrumb-item">Metrics</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col-9">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">user ID</th>
                <th scope="col">name</th>
                <th scope="col">aggregates</th>
                <th scope="col">measurements</th>
                <th scope="col">size</th>
              </tr>
            </thead>
            <tbody>
              {metrics.map(metric => <tr key={metric.id}>
                <th scope="row">{metric.id}</th>
                <td>{metric.userID}</td>
                <td>
                  <Link className="text-decoration-none" to={`${metric.uuid}`}>
                    <span className="me-2">{metric.name}</span>
                    <ArrowRightCircle/>
                  </Link>
                </td>
                <td>{formatAggregates(metric.aggregatesCount)}</td>
                <td>{formatMeasurements(metric.measurementsCount)}</td>
                <td>{size(metric.aggregatesCount, metric.measurementsCount)}</td>
              </tr>)}
            </tbody>
          </table>
        </div>
        <div className="col-3">
          <legend className="col-form-label">order by</legend>
          <div className="form-check">
            <input checked={orderBy === 'id'} className="form-check-input" onChange={_ => setOrderBy('id')} type="radio"/>
            <label className="form-check-label">ID</label>
          </div>
          <div className="form-check">
            <input checked={orderBy === 'userID'} className="form-check-input" onChange={_ => setOrderBy('userID')} type="radio"/>
            <label className="form-check-label">user ID</label>
          </div>
          <div className="form-check">
            <input checked={orderBy === 'name'} className="form-check-input" onChange={_ => setOrderBy('name')} type="radio"/>
            <label className="form-check-label">name</label>
          </div>
          <div className="form-check">
            <input checked={orderBy === 'aggregates'} className="form-check-input" onChange={_ => setOrderBy('aggregates')} type="radio"/>
            <label className="form-check-label">aggregates</label>
          </div>
          <div className="form-check">
            <input checked={orderBy === 'measurements'} className="form-check-input" onChange={_ => setOrderBy('measurements')} type="radio"/>
            <label className="form-check-label">measurements</label>
          </div>
          <div className="form-check">
            <input checked={orderBy === 'size'} className="form-check-input" onChange={_ => setOrderBy('size')} type="radio"/>
            <label className="form-check-label">size</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export async function loader(): Promise<any> {
  const url = `${process.env.REACT_APP_API_BASE_URL}/statjar/v2/metrics`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (!resp.ok) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

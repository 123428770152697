import { Link, Navigate, Outlet, useLoaderData, useLocation } from 'react-router-dom';

export default function StatjarRoot(): JSX.Element {
  const session = useLoaderData() as string | null;
  const location = useLocation();

  if (location.pathname === '/statjar' || location.pathname === '/statjar/') {
    // localStorage needs to be checked for logout redirections to work, as the
    // loader will not be evaluated again after redirecting.
    if (session === null || localStorage.getItem('statjar_session') === null) {
      return (
        <div className="container mt-5" style={{maxWidth: '850px'}}>
          <div className="d-flex justify-content-end mb-3">
            <Link style={{textDecoration: 'none'}} to="/statjar/login">🔒 Sign in</Link>
          </div>
          <h1>Statjar</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <hr/>
          <small>© 2023 Varoke Gaming Ltd.</small>
        </div>
      );
    }

    return <Navigate to="/statjar/home"/>;
  }

  if (session === null) {
    return <Navigate to="/statjar/login"/>;
  }

  return <Outlet/>;
}

export async function loader(): Promise<string | null> {
  return localStorage.getItem('statjar_session');
}

import React from 'react';
import logo from '../images/varoke-logo.svg';

function LandingFooter() {
  return (
    <footer className="border-top my-md-5 pt-4 pt-md-5">
      <div className="row">
        <div className="col-12 col-md">
          <img alt="Varoke Gaming" className="mb-2" src={logo} style={{height: '20px'}} />
          <small className="d-block mb-3 text-body-secondary">© 2023</small>
        </div>
        <div className="col-6 col-md"></div>
        <div className="col-6 col-md">
          <h5>Features</h5>
          <ul className="list-unstyled">
            <li className="mb-1">
              <a className="link-secondary text-decoration-none" href="/">Automated upgrades</a>
            </li>
            <li className="mb-1">
              <a className="link-secondary text-decoration-none" href="/">Managed backups</a>
            </li>
            <li className="mb-1">
              <a className="link-secondary text-decoration-none" href="/">Server analytics</a>
            </li>
            <li className="mb-1">
              <a className="link-secondary text-decoration-none" href="/">User management</a>
            </li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5>Information</h5>
          <ul className="list-unstyled">
            <li className="mb-1">
              <a className="link-secondary text-decoration-none" href="/acceptable-use-policy">Acceptable Use Policy</a>
            </li>
            <li className="mb-1">
              <a className="link-secondary text-decoration-none" href="https://minecraft.net/eula">Minecraft EULA</a>
            </li>
            <li className="mb-1">
              <a className="link-secondary text-decoration-none" href="/privacy-policy">Privacy Policy</a>
            </li>
            <li className="mb-1">
              <a className="link-secondary text-decoration-none" href="/terms-of-service">Terms of Service</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default LandingFooter;

import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useLoaderData } from 'react-router-dom';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';

const blue1 = '#5b9ffb';
const blue3 = '#1e6eea';

const brown1 = '#c28e64';
const brown3 = '#976746';

const deepBlue1 = '#bca4ec';
const deepBlue3 = '#643acb';

const green1 = '#a0e081';
const green3 = '#6cc349';

interface Aggregate {
  activeEntities: number;
  loadedChunks: number;
  onlineUsers: number;
  ticksPerSecond: number;
  timestamp: string;
}

function Analytics() {
  const aggregates = useLoaderData() as Aggregate[];

  const lastAggregate = aggregates[aggregates.length - 1];

  // TODO(revi): this shouldn't be here.
  const darkMode = localStorage.getItem('theme') === 'dark';

  const variant = darkMode ? 'dark' : 'light';

  return (
    <>
      <div className="row">
        <div className="col d-flex mb-4">
          <div className="flex-grow-1 ms-3"></div>
          <div>
            <DropdownButton title="Last 3 hours" variant={variant}>
              <Dropdown.Item href="#" disabled>Last hour</Dropdown.Item>
              <Dropdown.Item href="#">Last 3 hours</Dropdown.Item>
              <Dropdown.Item href="#" disabled>Last 12 hours</Dropdown.Item>
              <Dropdown.Item href="#" disabled>Last day</Dropdown.Item>
              <Dropdown.Item href="#" disabled>Last month</Dropdown.Item>
              <Dropdown.Item href="#" disabled>Last year</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="card mb-4 py-2 text-center">
            <div className="card-body">
              <div style={{color: green3, fontSize: '2em', fontWeight: 500, lineHeight: '1.1em'}}>{lastAggregate.activeEntities}</div>
              <div style={{fontSize: '.9em', opacity: .5}}>active entities</div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card mb-4">
            <div style={{height: '104px', width: '100%'}}>
              <ResponsiveContainer>
                <AreaChart data={aggregates} margin={{bottom: 0, left: 0, right: 0, top: 5}}>
                  <Area dataKey="activeEntities" fill={green1} fillOpacity={.25} stroke={green3} type="monotone"/>
                  <Tooltip content={<CustomTooltip metric="activeEntities"/>} wrapperStyle={{outline: 'none'}}/>
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="card mb-4 py-2 text-center">
            <div className="card-body">
              <div style={{color: brown3, fontSize: '2em', fontWeight: 500, lineHeight: '1.1em'}}>{lastAggregate.loadedChunks}</div>
              <div style={{fontSize: '.9em', opacity: .5}}>loaded chunks</div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card mb-4">
            <div style={{height: '104px', width: '100%'}}>
              <ResponsiveContainer>
                <AreaChart data={aggregates} margin={{bottom: 0, left: 0, right: 0, top: 5}}>
                  <Area dataKey="loadedChunks" fill={brown1} fillOpacity={.25} stroke={brown3} type="monotone"/>
                  <Tooltip content={<CustomTooltip metric="loadedChunks"/>} wrapperStyle={{outline: 'none'}}/>
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="card mb-4 py-2 text-center">
            <div className="card-body">
              <div style={{color: blue3, fontSize: '2em', fontWeight: 500, lineHeight: '1.1em'}}>{lastAggregate.onlineUsers}</div>
              <div style={{fontSize: '.9em', opacity: .5}}>online users</div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card mb-4">
            <div style={{height: '104px', width: '100%'}}>
              <ResponsiveContainer>
                <AreaChart data={aggregates} margin={{bottom: 0, left: 0, right: 0, top: 5}}>
                  <Area dataKey="onlineUsers" fill={blue1} fillOpacity={.25} stroke={blue3} type="monotone"/>
                  <Tooltip content={<CustomTooltip metric="onlineUsers"/>} wrapperStyle={{outline: 'none'}}/>
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="card mb-4 py-2 text-center">
            <div className="card-body">
              <div style={{color: deepBlue3, fontSize: '2em', fontWeight: 500, lineHeight: '1.1em'}}>{lastAggregate.ticksPerSecond}</div>
              <div style={{fontSize: '.9em', opacity: .5}}>ticks per second</div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card mb-4">
            <div style={{height: '104px', width: '100%'}}>
              <ResponsiveContainer>
                <AreaChart data={aggregates} margin={{bottom: 0, left: 0, right: 0, top: 5}}>
                  <Area dataKey="ticksPerSecond" fill={deepBlue1} fillOpacity={.25} stroke={deepBlue3} type="monotone"/>
                  <Tooltip content={<CustomTooltip metric="ticksPerSecond"/>} wrapperStyle={{outline: 'none'}}/>
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface CustomTooltipProps {
  active?: any;
  label?: any;
  metric: string;
  payload?: any;
}

const suffixes: {[key: string]: string} = {
  'activeEntities': 'active entities',
  'loadedChunks': 'loaded chunks',
  'onlineUsers': 'online users',
  'ticksPerSecond': 'ticks per second',
};

function CustomTooltip({active, label, metric, payload}: CustomTooltipProps) {
  if (active && payload && payload.length) {
    return (
      <div className="card" style={{fontSize: '.9rem'}}>
        <div className="card-body" style={{padding: '12px'}}>
          <div style={{fontWeight: 500, marginTop: '-4px'}}>{payload[0].payload.timestamp}</div>
          <div className="mt-1">
            <span style={{color: 'rgb(59, 87, 106)', fontWeight: 500}}>{payload[0].payload[metric]}</span>
            <span style={{opacity: .7}}> {suffixes[metric]}</span>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export async function loader({ params }: any) {
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const url = `${apiBaseURL}/bff/${params.serverID}/analytics`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export default Analytics;

import React from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import {
  CartesianGrid, Label,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Annotation, MetricWithMetadata, Point } from '../types/Statjar';
import { formatAggregates, formatMeasurements } from './StatjarMetrics';

export function aggregatesSizeMiB(aggregatesCount: number): number {
  return aggregatesCount * 48 / Math.pow(1024, 2);
}

export function measurementsSizeMiB(measurementsCount: number): number {
  return measurementsCount * 25 / Math.pow(1024, 2);
}

export function format(x: number): string { return new Intl.NumberFormat().format(x); }

const colorBlue = 'rgba(13, 110, 253, .75)';
const colorGreen = 'rgba(25, 135, 84, .25)';
const colorRed = 'rgba(220, 53, 69, .25)';

const labelStyle: React.CSSProperties = {
  fill: 'rgba(0, 0, 0, .9)',
  fontFamily: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  fontSize: '10px',
};

function newChart(annotations: Annotation[], points: Point[]): JSX.Element {
  const annotatedAreas = annotations.filter(x => x.timestampFrom !== x.timestampTo);
  const annotatedLines = annotations.filter(x => x.timestampFrom === x.timestampTo);

  return (
    <ResponsiveContainer height={250} width="100%">
      <LineChart data={points}>
        <CartesianGrid horizontalPoints={[50, 100, 150, 200]}
                       strokeDasharray="3 3"
                       verticalPoints={[0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500]}/>
        <Line dataKey="avg" dot={false} stroke={colorBlue} type="monotone"/>
        <Line dataKey="max" dot={false} stroke={colorGreen} type="monotone"/>
        <Line dataKey="min" dot={false} stroke={colorRed} type="monotone"/>
        {annotatedAreas.map((x, idx) => <ReferenceArea fill="rgba(0, 0, 0, .25)" key={`area-${idx}`} x1={x.timestampFrom} x2={x.timestampTo}>
          <Label position="insideTop" style={labelStyle} value={x.description}/>
        </ReferenceArea>)}
        {annotatedLines.map((x, idx) => <ReferenceLine key={`line-${idx}`} stroke="rgba(0, 0, 0, .25)" x={x.timestampFrom}>
          <Label position="insideTop" style={labelStyle} value={x.description}/>
        </ReferenceLine>)}
        <Tooltip/>
        <XAxis dataKey="timestamp" domain={['dataMin', 'dataMax']} hide={true} type="number"/>
        <YAxis domain={['dataMin', 'dataMax']} hide={true}/>
      </LineChart>
    </ResponsiveContainer>
  );
}

export default function StatjarMetric(): JSX.Element {
  const metric = useLoaderData() as MetricWithMetadata;

  const {aggregatesCount, measurementsCount} = metric;
  const aggregatesSize = aggregatesSizeMiB(aggregatesCount);
  const measurementsSize = measurementsSizeMiB(measurementsCount);
  const size = aggregatesSize + measurementsSize;

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link relative="path" to="..">Metrics</Link>
              </li>
              <li aria-current="page" className="active breadcrumb-item">{metric.name}</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <table className="table table-bordered">
            <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">user ID</th>
              <th scope="col">name</th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">{metric.id}</th>
                <td>{metric.userID}</td>
                <td>{metric.name}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-lg-6">
          <table className="table table-bordered">
            <thead>
            <tr>
              <th scope="col">aggregates</th>
              <th scope="col">measurements</th>
              <th scope="col">size</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                {formatAggregates(aggregatesCount)}
              </td>
              <td>
                {formatMeasurements(measurementsCount)}
              </td>
              <td>
                {`${size.toFixed(2)} MiB`}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-xl-4 d-flex flex-column mb-3">
          <span className="mb-1">1 hour @ 1 minute</span>
          {newChart(metric.annotations, metric.oneHour)}
        </div>
        <div className="col-md-6 col-xl-4 d-flex flex-column mb-3">
          <span className="mb-1">12 hours @ 10 minutes</span>
          {newChart(metric.annotations, metric.twelveHours)}
        </div>
        <div className="col-md-6 col-xl-4 d-flex flex-column mb-3">
          <span className="mb-1">1 day @ 15 minutes</span>
          {newChart(metric.annotations, metric.oneDay)}
        </div>
        <div className="col-md-6 col-lg-4 d-flex flex-column mb-3">
          <span className="mb-1">1 week @ 1 hour</span>
          {newChart(metric.annotations, metric.oneWeek)}
        </div>
        <div className="col-md-6 col-lg-4 d-flex flex-column mb-3">
          <span className="mb-1">1 month @ 1 day</span>
          {newChart(metric.annotations, metric.oneMonth)}
        </div>
        <div className="col-md-6 col-lg-4 d-flex flex-column mb-3">
          <span className="mb-1">1 year @ 1 week</span>
          {newChart(metric.annotations, metric.oneYear)}
        </div>
      </div>
    </div>
  );
}

export async function loader({params}: any): Promise<MetricWithMetadata> {
  const apiBaseURL = `${process.env.REACT_APP_API_BASE_URL}/statjar/v2`;

  const resp = await fetch(`${apiBaseURL}/metrics/${params.metricUUID}`);
  if (!resp.ok) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  const metadataResp = await fetch(`${apiBaseURL}/metrics/${params.metricUUID}/metadata`);
  if (!metadataResp.ok) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return {
    ...await resp.json(),
    ...await metadataResp.json(),
  };
}

import React from 'react';
import varokeLogo from '../images/varoke-logo.svg';

function ResetPassword() {
  return (
    <div className="container" style={{marginTop: '5vw'}}>
      <div className="justify-content-center mb-4 row">
        <div className="col-lg-6 col-md-8 col-sm-10 col-xl-5">
          <div className="mb-4 mt-2 text-center">
            <img alt="Varoke Gaming" className="d-inline" src={varokeLogo} style={{height: '32px'}} />
          </div>
          <div className="card">
            <div className="card-body">
              <h5>Reset your password</h5>
              <div className="mb-3" style={{fontSize: '.9em', opacity: .6}}>
                Enter the email address associated with your account and we will send you a link to reset your password.
              </div>
              <form>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input className="form-control" type="email"/>
                </div>
                <div className="d-grid">
                  <button className="btn btn-lg btn-primary" type="submit">Send email</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

import { Link } from 'react-router-dom';

export default function StatjarNamespace(): JSX.Element {
  return (
    <div className="container mt-5" style={{maxWidth: '850px'}}>
      <div className="d-flex justify-content-between mb-3">
        <Link style={{textDecoration: 'none'}} to="/statjar/home">🏠 Home</Link>
        <Link style={{textDecoration: 'none'}} to="/statjar/logout">🔓 Log out</Link>
      </div>
      <h1>📦 Namespace</h1>
      <h2>📈 Metrics</h2>
      <p>You have no metrics.</p>
      <hr/>
      <small>© 2023 Varoke Gaming Ltd.</small>
    </div>
  );
}

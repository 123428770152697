import { faBan, faGift, faUserGroup, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useFetcher, useLoaderData } from 'react-router-dom';
import Select from 'react-select';
import diamond from '../images/items/diamond.png';
import goldIngot from '../images/items/gold_ingot.png';
import ironIngot from '../images/items/iron_ingot.png';

interface LoaderData {
  groups: string[];
  players: Player[];
}

interface Option {
  icon: string
  label: string
  value: string
}

interface Player {
  group: string;
  lastSeen: string;
  name: string;
  ping: string;
  playtime: string;
  registered: string;
  uuid: string;
}

function formatOptionLabel(option: Option) {
  return (
    <div className="align-items-center d-flex">
      <img alt={option.label} className="me-2" height="24" src={option.icon} />
      <span>{option.label}</span>
    </div>
  );
}

function formatPing(value: string) {
  if (value === 'Unavailable') {
    return <span>{value}</span>
  }

  const ping = Number(value.split(' ')[0]);

  if (ping < 100) {
    return <span className="text-success">{value}</span>
  }

  if (ping < 250) {
    return <span className="text-warning">{value}</span>
  }

  return <span className="text-danger">{value}</span>
}

const options: Option[] = [
  {icon: diamond, label: 'Diamond', value: 'diamond'},
  {icon: goldIngot, label: 'Gold Ingot', value: 'gold_ingot'},
  {icon: ironIngot, label: 'Iron Ingot', value: 'iron_ingot'},
];

function Players() {
  const fetcher = useFetcher();
  const loaderData = useLoaderData() as LoaderData;
  const [activePlayer, setActivePlayer] = useState<Player | null>(null);
  const [selectedGroup, setSelectedGroup] = useState('default');
  const [show, setShow] = useState(false);
  const [showGiveItems, setShowGiveItems] = useState(false);

  const handleClose = () => {
    setShow(false);
    setActivePlayer(null);
    setSelectedGroup('default');
  };

  const handleCloseGiveItems = () => {
    setShowGiveItems(false);
    setActivePlayer(null);
  };

  const handleShow = (player: Player) => {
    setActivePlayer(player);
    setSelectedGroup(player.group);
    setShow(true);
  };

  const handleShowGiveItems = (player: Player) => {
    setActivePlayer(player);
    setShowGiveItems(true);
  };

  const isIdle = fetcher.state === 'idle';

  useEffect(() => {
    if (fetcher.data) {
      handleClose();
      handleCloseGiveItems();
    }
  }, [fetcher.data]);

  return (
    <>
      <div className="row">
        <div className="col">
          <table className="align-middle mb-0 table">
            <thead>
            <tr>
              <th scope="col">Player</th>
              <th scope="col">Group</th>
              <th scope="col">Last seen</th>
              <th scope="col">Ping</th>
              <th scope="col">Playtime</th>
              <th scope="col">Registered</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody className="table-group-divider">
            {loaderData.players.map(player => <tr key={player.uuid}>
              <th scope="row">
                <img alt={player.name} className="me-3" src={`https://crafatar.com/renders/head/${player.uuid}?default=MHF_Steve&overlay`} style={{height: '24px'}} />
                <span className="me-2">{player.name}</span>
                {formatUUID(player.uuid)}
              </th>
              <td>{player.group}</td>
              <td>{player.lastSeen}</td>
              <td>
                {formatPing(player.ping)}
              </td>
              <td>{player.playtime}</td>
              <td>{player.registered}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" size="sm" variant="primary">
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#"><FontAwesomeIcon icon={faBan} style={{marginRight: '4px'}}/> Ban</Dropdown.Item>
                    <Dropdown.Item onClick={_ => handleShowGiveItems(player)}><FontAwesomeIcon icon={faGift} style={{marginRight: '4px'}}/> Give items</Dropdown.Item>
                    <Dropdown.Item href="#"><FontAwesomeIcon icon={faUserSlash}/> Kick</Dropdown.Item>
                    <Dropdown.Item onClick={_ => handleShow(player)}><FontAwesomeIcon icon={faUserGroup}/> Set group</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>)}
            </tbody>
          </table>
        </div>
      </div>
      <Modal onHide={handleClose} show={show}>
        <fetcher.Form action="set-group" method="post">
          <Modal.Header closeButton>
            <Modal.Title>Add user to group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label" htmlFor="group">Group</label>
              <select className="form-select" defaultValue={selectedGroup} id="group" name="group">
                {loaderData.groups.map(group => <option key={group}>{group}</option>)}
              </select>
            </div>

            <div>
              <label className="form-label" htmlFor="name">Username</label>
              <input className="form-control" disabled id="name" type="text" value={activePlayer?.name || ''} />
            </div>

            <input name="name" type="hidden" value={activePlayer?.name || ''} />
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={!isIdle} onClick={handleClose} variant="secondary">Close</Button>
            <Button disabled={!isIdle} type="submit" variant="primary">Save Changes</Button>
          </Modal.Footer>
        </fetcher.Form>
      </Modal>
      <Modal onHide={handleCloseGiveItems} show={showGiveItems}>
        <fetcher.Form action="give-items" method="post">
          <Modal.Header closeButton>
            <Modal.Title>Give items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label" htmlFor="username">Username</label>
              <input className="form-control" disabled id="username" type="text" value={activePlayer?.name || ''} />
            </div>

            <input name="username" type="hidden" value={activePlayer?.name || ''} />

            <div className="mb-3">
              <label className="form-label" htmlFor="item">Item</label>
              <Select formatOptionLabel={formatOptionLabel} id="item" name="item" options={options} />
            </div>

            <div>
              <label className="form-label" htmlFor="amount">Amount</label>
              <input className="form-control" defaultValue="1" id="amount" max="64" min="1" name="amount" type="number" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={!isIdle} onClick={handleCloseGiveItems} variant="secondary">Close</Button>
            <Button disabled={!isIdle} type="submit" variant="primary">Save Changes</Button>
          </Modal.Footer>
        </fetcher.Form>
      </Modal>
    </>
  );
}

function formatUUID(uuid: string) {
  const shortUUID = uuid.split('-').slice(-1)[0];
  return (
    <span className="fw-normal text-secondary">
      <span style={{opacity: .1}}>{shortUUID.charAt(0)}</span>
      <span style={{opacity: .4}}>{shortUUID.charAt(1)}</span>
      <span style={{opacity: .7}}>{shortUUID.charAt(2)}</span>
      {shortUUID.slice(3)}
    </span>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function giveItemsAction({params, request}: any): Promise<any> {
  const formData = await request.formData();
  const data = {
    // TODO(revi): ensure we work with valid numbers at this time. Go isn't
    // failing when this is null, it reads it as a 0.
    amount: Number(formData.get('amount')),
    item: formData.get('item'),
    username: formData.get('username'),
  };

  console.log(data);

  const url = `${apiBaseURL}/bff/${params.serverID}/players/give_items`;
  const resp = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'POST',
  });

  if (resp.ok) {
    // returns a new object to trigger useEffect on new responses.
    return {};
  }

  throw new Response(resp.statusText, {status: resp.status});
}

export async function loader({ params }: any) {
  const url = `${apiBaseURL}/bff/${params.serverID}/players`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export async function setGroupAction({params, request}: any): Promise<any> {
  const formData = await request.formData();
  const data = {
    group: formData.get('group'),
    name: formData.get('name'),
  };

  console.log(data);

  const url = `${apiBaseURL}/bff/${params.serverID}/players/set_group`;
  const resp = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'PUT',
  });

  if (resp.ok) {
    // returns a new object to trigger useEffect on new responses.
    return {};
  }

  throw new Response(resp.statusText, {status: resp.status});
}

export default Players;

import React, { useState } from 'react';
import { Form, useLoaderData, useNavigation } from 'react-router-dom';

function EssentialsX() {
  const loaderData = useLoaderData() as any;
  const navigation = useNavigation();

  const [addPrefixInPlayerlist, setAddPrefixInPlayerlist] = useState(loaderData.addPrefixInPlayerlist);
  const [addSuffixInPlayerlist, setAddSuffixInPlayerlist] = useState(loaderData.addSuffixInPlayerlist);
  const [changePlayerlist, setChangePlayerlist] = useState(loaderData.changePlayerlist);
  const [hideDisplaynameInVanish, setHideDisplaynameInVanish] = useState(loaderData.hideDisplaynameInVanish);

  const {maxNickLength, nicknamePrefix} = loaderData;

  const isGameSettingsIdle = !navigation.formAction?.endsWith('game-settings');

  return (
    <Form action="game-settings" method="post">
      <div className="card mb-4" id="game-settings">
        <div className="list-group list-group-flush">
          <div className="list-group-item py-3">
            <h5>Usernames</h5>
            <div style={{fontSize: '.9em', opacity: .6}}>
              After changes are made to the server properties, the server must
              be restarted for them to take effect.
            </div>
          </div>

          <div className="list-group-item py-3">
            <h6>Add prefix in playerlist</h6>
            <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
              When this option is enabled, player prefixes will be shown in the
              playerlist. The value of change-playerlist has to be true.
            </div>
            <div className="form-check form-switch">
              <input className="form-check-input"
                     type="checkbox"
                     role="switch"
                     id="addPrefixInPlayerlist"
                     name="addPrefixInPlayerlist"
                     defaultChecked={addPrefixInPlayerlist}
                     onChange={() => setAddPrefixInPlayerlist(!addPrefixInPlayerlist)}/>
              <label className="form-check-label" htmlFor="addPrefixInPlayerlist">
                {addPrefixInPlayerlist ? 'On' : 'Off'}
              </label>
            </div>
          </div>

          <div className="list-group-item py-3">
            <h6>Add suffix in playerlist</h6>
            <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
              When this option is enabled, player suffixes will be shown in the
              playerlist. The value of change-playerlist has to be true.
            </div>
            <div className="form-check form-switch">
              <input className="form-check-input"
                     type="checkbox"
                     role="switch"
                     id="addSuffixInPlayerlist"
                     name="addSuffixInPlayerlist"
                     defaultChecked={addSuffixInPlayerlist}
                     onChange={() => setAddSuffixInPlayerlist(!addSuffixInPlayerlist)}/>
              <label className="form-check-label" htmlFor="addSuffixInPlayerlist">
                {addSuffixInPlayerlist ? 'On' : 'Off'}
              </label>
            </div>
          </div>

          <div className="list-group-item py-3">
            <h6>Change playerlist</h6>
            <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
              When this option is enabled, the (tab) player list will be updated
              with the displayname.
            </div>
            <div className="form-check form-switch">
              <input className="form-check-input"
                     type="checkbox"
                     role="switch"
                     id="changePlayerlist"
                     name="changePlayerlist"
                     defaultChecked={changePlayerlist}
                     onChange={() => setChangePlayerlist(!changePlayerlist)}/>
              <label className="form-check-label" htmlFor="changePlayerlist">
                {changePlayerlist ? 'On' : 'Off'}
              </label>
            </div>
          </div>

          <div className="list-group-item py-3">
            <h6>Hide displayname in vanish</h6>
            <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
              When this option is enabled, display names for hidden users will
              not be shown. This prevents players from being able to see that
              they are online while vanished.
            </div>
            <div className="form-check form-switch">
              <input className="form-check-input"
                     type="checkbox"
                     role="switch"
                     id="hideDisplaynameInVanish"
                     name="hideDisplaynameInVanish"
                     defaultChecked={hideDisplaynameInVanish}
                     onChange={() => setHideDisplaynameInVanish(!hideDisplaynameInVanish)}/>
              <label className="form-check-label" htmlFor="hideDisplaynameInVanish">
                {hideDisplaynameInVanish ? 'On' : 'Off'}
              </label>
            </div>
          </div>

          <div className="list-group-item py-3">
            <h6>Max nick length</h6>
            <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
              The maximum length allowed in nicknames. The nickname prefix is
              included in this.
            </div>
            <input className="form-control w-50"
                   type="text"
                   placeholder=""
                   name="maxNickLength"
                   defaultValue={maxNickLength}/>
          </div>

          <div className="list-group-item py-3">
            <h6>Nickname prefix</h6>
            <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
              The character(s) to prefix all nicknames, so that you know they
              are not true usernames.
            </div>
            <input className="form-control w-50"
                   type="text"
                   placeholder=""
                   name="nicknamePrefix"
                   defaultValue={nicknamePrefix}/>
          </div>
        </div>
        <div className="card-footer py-3 text-end">
          <button className="btn btn-primary btn-sm" disabled={!isGameSettingsIdle} style={{width: '125px'}} type="submit">
            {!isGameSettingsIdle &&
                <span aria-hidden="true" className="me-2 spinner-border spinner-border-sm" role="status"></span>}
            {isGameSettingsIdle ? 'Save changes' : 'Loading...'}
          </button>
        </div>
      </div>
    </Form>
  );
}

export async function loader({ params }: any): Promise<any> {
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const url = `${apiBaseURL}/bff/${params.serverID}/essentialsx`
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export default EssentialsX;

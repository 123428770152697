import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/varoke-logo.svg';

function LandingHeader() {
  return (
    <header>
      <div className="align-items-center border-bottom d-flex mb-4 pb-3">
        <Link to="/">
          <img alt="Varoke Gaming" src={logo} style={{height: '32px'}} />
        </Link>
        <nav className="d-inline-flex ms-auto">
          <Link className="link-body-emphasis py-2 text-decoration-none" to="/login">Sign in</Link>
        </nav>
      </div>
    </header>
  );
}

export default LandingHeader;

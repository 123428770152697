import React from 'react';
import LandingFooter from '../components/LandingFooter';
import LandingHeader from '../components/LandingHeader';

function AcceptableUsePolicy() {
  return (
    <div className="container pt-3" style={{maxWidth: '960px'}}>
      <LandingHeader/>
      <section>
        <h1>Acceptable Use Policy</h1>
        <p>
          This Acceptable Use Policy sets out the terms between you and Varoke Gaming under which you may access and use
          our website at varokegaming.com, its subdomains, associated domains, and our associated hosting services, any
          or all of which are hereafter referred to as “Our Services”. Our Acceptable Use Policy applies to all visitors
          to and users of Our Services and your use of Our Services means you accept and agree to abide by all the terms
          in this Acceptable Use Policy.
        </p>
        <h2>Prohibited uses</h2>
        <p>
          You may use Our Services only for lawful purposes. You may not use Our Services to:
        </p>
        <ul>
          <li>
            execute or participate in a Denial of Service (DoS) attack designed to adversely affect the operation of any
            computer software, hardware, website, network or other services;
          </li>
          <li>
            probe or scan for open ports (port scanning) on a network;
          </li>
          <li>
            transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or
            any other form of similar solicitation (spam);
          </li>
          <li>
            knowingly transmit, or cause to transmit, any data that contains viruses, Trojan horses, worms, rootkits,
            keystroke loggers, spyware, adware or any other harmful programs (malware) or similar computer code designed
            to adversely affect the operation of any computer software, hardware, website, network or other services;
          </li>
          <li>
            publish, distribute or disseminate any content that incites discrimination, hate or violence towards ethnic,
            religious or other minority groups;
          </li>
          <li>
            publish, distribute or disseminate any defamatory, obscene, or otherwise unlawful content such as, but not
            limited to, child pornography;
          </li>
          <li>
            make available any pieces of text, imagery, software that directly or indirectly infringes the intellectual
            property rights of another party;
          </li>
          <li>
            act in any other way that breaches any applicable local, national or international laws or regulations; or
          </li>
          <li>
            conduct or participate in any activity that Varoke Gaming considers to be inappropriate or unethical.
          </li>
        </ul>
        <p>
          You also agree:
        </p>
        <ul>
          <li>
            not to reproduce, duplicate, copy or re-sell any part of Our Services in contravention of the provisions of
            our Terms Of Service; and
          </li>
          <li>
            not to access without authority, interfere with, damage or disrupt: any equipment or network on which Our
            Services depend or operate; any software used in the operation of Our Services; or any equipment or network
            or software owned or used by any third party.
          </li>
        </ul>
        <p>
          If you are made aware of any breaches of the above policies, please notify us as soon as possible <a href="mailto:support@varoke.dev">here</a>.
        </p>
        <h2>Suspension and termination</h2>
        <p>
          We will determine, at our sole discretion, whether there has been a breach of this Acceptable Use Policy
          through your use of Our Services. When a breach of this policy has occurred, we may take such action as we
          deem appropriate.
        </p>
        <p>
          Failure to comply with this Acceptable Use Policy constitutes a material breach of the Terms of Service upon
          which you are permitted to use Our Services, and may result in our taking all or any of the following actions:
        </p>
        <ul>
          <li>
            Issue of a warning to you
          </li>
          <li>
            Immediate, temporary or permanent removal of any posting or material uploaded by you
          </li>
          <li>
            Immediate, temporary or permanent withdrawal of your right to use Our Services
          </li>
          <li>
            Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not
            limited to, reasonable administrative and legal costs) resulting from the breach
          </li>
          <li>
            Disclosure of such information to relevant law enforcement authorities as we reasonably feel is necessary
          </li>
          <li>
            Further legal action against you
          </li>
        </ul>
        <p>
          We exclude liability for actions taken in response to breaches of this Acceptable Use Policy. The responses
          described in this policy are not limited, and we may take any other action we reasonably deem appropriate.
        </p>
        <h2>Changes to Acceptable Use Policy</h2>
        <p>
          We may revise this Acceptable Use Policy at any time and at our sole discretion. You are expected to check
          this policy from time to time to note any changes we make, as they are legally binding on you. Some terms
          within this Acceptable Use Policy may also be superseded by changes to the other policies noted above,
          published on our website.
        </p>
      </section>
      <LandingFooter/>
    </div>
  );
}

export default AcceptableUsePolicy;

import React from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import {
  CartesianGrid, Label,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Annotation, Application, Point } from '../types/Statjar';

function CustomTooltip({active, label, payload}: any) {
  if (active && payload && payload.length) {
    const {avg, max, min} = payload[0].payload;
    const timestamp = new Date(label * 1000).toLocaleString();

    return (
      <div className="card" style={{fontSize: '.9rem'}}>
        <div className="card-body" style={{padding: '12px'}}>
          <div style={{fontWeight: 500, marginTop: '-4px'}}>{timestamp}</div>
          <div className="mt-1">
            <span style={{color: colorRed, fontWeight: 500}}>{`min: ${min}`}</span>
            <span className="mx-2" style={{color: colorBlue, fontWeight: 500}}>{`avg: ${avg}`}</span>
            <span style={{color: colorGreen, fontWeight: 500}}>{`max: ${max}`}</span>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

const colorBlue = 'rgb(13, 110, 253)';
const colorGreen = 'rgb(25, 135, 84)';
const colorRed = 'rgb(220, 53, 69)';

const labelStyle: React.CSSProperties = {
  fill: 'rgba(0, 0, 0, .9)',
  fontFamily: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  fontSize: '10px',
};

function newChart(annotations: Annotation[], points: Point[]): JSX.Element {
  const annotatedAreas = annotations.filter(x => x.timestampFrom !== x.timestampTo);
  const annotatedLines = annotations.filter(x => x.timestampFrom === x.timestampTo);

  return (
    <ResponsiveContainer height={250} width="100%">
      <LineChart data={points}>
        <CartesianGrid horizontalPoints={[50, 100, 150, 200]}
                       strokeDasharray="3 3"
                       verticalPoints={[0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1050, 1100]}/>
        <Line dataKey="avg" dot={false} stroke={colorBlue} strokeOpacity={.75} type="monotone"/>
        <Line dataKey="max" dot={false} stroke={colorGreen} strokeOpacity={.25} type="monotone"/>
        <Line dataKey="min" dot={false} stroke={colorRed} strokeOpacity={.25} type="monotone"/>
        {annotatedAreas.map((x, idx) => <ReferenceArea fill="rgba(0, 0, 0, .25)" ifOverflow="visible" key={`area-${idx}`} x1={x.timestampFrom} x2={x.timestampTo}>
          <Label position="insideTop" style={labelStyle} value={x.description}/>
        </ReferenceArea>)}
        {annotatedLines.map((x, idx) => <ReferenceLine ifOverflow="visible" key={`line-${idx}`} stroke="rgba(0, 0, 0, .25)" x={x.timestampFrom}>
          <Label position="insideTop" style={labelStyle} value={x.description}/>
        </ReferenceLine>)}
        <Tooltip content={<CustomTooltip/>} wrapperStyle={{outline: 'none'}}/>
        <XAxis dataKey="timestamp" domain={['dataMin', 'dataMax']} hide={true} type="number"/>
        <YAxis domain={['dataMin', 'dataMax']} hide={true}/>
      </LineChart>
    </ResponsiveContainer>
  );
}

export default function StatjarApplication(): JSX.Element {
  const application = useLoaderData() as Application;

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link relative="path" to="..">Applications</Link>
              </li>
              <li aria-current="page" className="active breadcrumb-item">{application.name}</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <table className="table table-bordered">
            <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">user ID</th>
              <th scope="col">name</th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">{application.id}</th>
                <td>{application.userID}</td>
                <td>{application.name}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {application.metrics.map(metric => <div className="row" key={metric.id}>
        <div className="col d-flex flex-column mb-3">
          <span className="mb-1">{metric.name}</span>
          {newChart(metric.annotations, metric.oneDay)}
        </div>
      </div>)}
    </div>
  );
}

export async function loader({params}: any): Promise<Application> {
  const apiBaseURL = `${process.env.REACT_APP_API_BASE_URL}/statjar/v2`;

  const resp = await fetch(`${apiBaseURL}/applications/${params.applicationUUID}`);
  if (!resp.ok) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

import { useState } from 'react';
import { Form, redirect, useActionData, useLoaderData } from 'react-router-dom';

export default function StatjarOnboarding(): JSX.Element {
  const actionError = useActionData() as Error | null;
  const loaderError = useLoaderData() as Error | null;

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className="container mt-5" style={{maxWidth: '500px'}}>
      {loaderError && <div className="alert alert-danger" role="alert">{loaderError.message}</div>}
      {actionError && <div className="alert alert-danger" role="alert">{actionError.message}</div>}
      {actionError === null && <div className="alert alert-success" role="alert">
          Success! Check your email to confirm your account
      </div>}
      {loaderError === null && <Form method="post">
          <div className="mb-3">
              <label className="form-label" htmlFor="name">Name</label>
              <input className="form-control" id="name" name="name" onChange={e => setName(e.target.value)} type="text"
                     value={name}/>
          </div>
          <div className="mb-3">
              <label className="form-label" htmlFor="password">Password</label>
              <input className="form-control" id="password" name="password" onChange={e => setPassword(e.target.value)}
                     type="password" value={password}/>
          </div>
          <button className="btn btn-primary" disabled={name === '' || password === ''} type="submit">Submit</button>
      </Form>}
    </div>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function action({params, request}: any): Promise<Error | Response> {
  const formData = await request.formData();
  const data = {
    activationToken: params.activationToken,
    name: formData.get('name'),
    password: formData.get('password'),
  };

  console.log(data);

  const url = `${apiBaseURL}/statjar/v3/onboarding`;
  try {
    const resp = await fetch(url, {
      body: JSON.stringify(data),
      method: 'POST',
    });

    if (resp.status === 400) {
      return new Error('That doesn\'t look like an email address');
    }

    if (resp.status === 409) {
      return new Error('That email address is already registered');
    }

    if (!resp.ok) {
      return new Error('It was not possible to handle your request');
    }

    const jsonResp = await resp.json();
    localStorage.setItem('statjar_session', jsonResp.session);

    return redirect('/statjar');
  } catch (error) {
    return new Error('It was not possible to submit your request');
  }
}

export async function loader({params}: any) {
  const url = `${apiBaseURL}/statjar/v3/onboarding/${params.activationToken}`;
  try {
    const resp = await fetch(url);

    if (resp.status === 400) {
      return new Error('The activation token is invalid or it\'s been used already');
    }

    if (!resp.ok) {
      return new Error('It was not possible to handle your request');
    }
  } catch (error) {
    return new Error('It was not possible to submit your request');
  }

  return null;
}

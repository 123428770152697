import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useFetcher, useLoaderData } from 'react-router-dom';

interface User {
  name: string;
  uuid: string;
}

function Whitelist() {
  const fetcher = useFetcher();
  const loaderData = useLoaderData() as User[];
  const [username, setUsername] = useState('');

  useEffect(() => {
    setUsername('');
  }, [fetcher.data]);

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-header" style={{padding: '12px'}}>
            <fetcher.Form action="add" className="g-3 row" method="post">
              <div className="col-10 col-lg-11">
                <input autoCapitalize="off"
                       autoComplete="off"
                       autoCorrect="off"
                       className="form-control"
                       name="name"
                       onChange={e => setUsername(e.target.value)}
                       placeholder="Add a username..."
                       spellCheck={false}
                       value={username}/>
              </div>
              <div className="col-2 col-lg-1">
                <button className="btn btn-primary w-100" disabled={username === ''} type="submit">Add</button>
              </div>
            </fetcher.Form>
          </div>
          <div className="list-group list-group-flush">
            {loaderData.map(user => <div className="list-group-item" key={user.uuid}>
              <fetcher.Form action="delete" method="post">
                <input name="uuid" type="hidden" value={user.uuid} />
                <div className="d-flex">
                  <div className="align-items-center d-flex flex-grow-1" style={{opacity: 1}}>
                    <div>
                      <img alt="" className="me-2 d-inline-block" src={`https://crafatar.com/renders/head/${user.uuid}?default=MHF_Steve&overlay`} style={{height: '24px'}}/>
                      <span style={{wordBreak: 'break-all'}}>
                        {user.name}<span className="ms-2" style={{opacity: .3}}>{user.uuid}</span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <button className="btn btn-link me-n3 my-n1" style={{color: 'rgb(34, 34, 34)', opacity: .3}} type="submit">
                      <FontAwesomeIcon icon={faTimes}/>
                    </button>
                  </div>
                </div>
              </fetcher.Form>
            </div>)}
          </div>
        </div>
      </div>
    </div>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function addWhitelistAction({params, request}: any): Promise<any> {
  const formData = await request.formData();
  const name = formData.get('name');

  const url = `${apiBaseURL}/bff/${params.serverID}/whitelist/${name}`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'POST',
  });

  if (resp.ok) {
    return {};
  }

  throw new Response(resp.statusText, {status: resp.status});
}

export async function deleteWhitelistAction({params, request}: any): Promise<any> {
  const formData = await request.formData();
  const uuid = formData.get('uuid');

  const url = `${apiBaseURL}/bff/${params.serverID}/whitelist/${uuid}`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'DELETE',
  });

  if (resp.ok) {
    return {};
  }

  throw new Response(resp.statusText, {status: resp.status});
}

export async function loader({ params }: any) {
  const url = `${apiBaseURL}/bff/${params.serverID}/whitelist`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export default Whitelist;

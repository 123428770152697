import { useState } from 'react';
import { Form, useActionData } from 'react-router-dom';

export default function StatjarSignup(): JSX.Element {
  const error = useActionData() as Error | null | undefined;

  const [email, setEmail] = useState('');

  return (
    <div className="container mt-5" style={{maxWidth: '500px'}}>
      {error && <div className="alert alert-danger" role="alert">{error.message}</div>}
      {error === null && <div className="alert alert-success" role="alert">
          Success! Check your email to confirm your account
      </div>}
      {error !== null && <Form method="post">
          <div className="mb-3">
              <label className="form-label" htmlFor="email">
                  Email address
              </label>
              <input aria-describedby="emailHelp" className="form-control" id="email" name="email"
                     onChange={e => setEmail(e.target.value)} type="email" value={email}/>
              <div className="form-text" id="emailHelp">
                  We'll never share your email with anyone else.
              </div>
          </div>
          <button className="btn btn-primary" disabled={email === ''} type="submit">Submit</button>
      </Form>}
    </div>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function action({request}: any) {
  const formData = await request.formData();
  const data = {
    email: formData.get('email'),
  };

  console.log(data);

  if (typeof data.email !== 'string' || !data.email.includes('@')) {
    return new Error('That doesn\'t look like an email address');
  }

  const url = `${apiBaseURL}/statjar/v3/signup`;
  try {
    const resp = await fetch(url, {
      body: JSON.stringify(data),
      method: 'POST',
    });

    if (resp.status === 400) {
      return new Error('That doesn\'t look like an email address');
    }

    if (resp.status === 409) {
      return new Error('That email address is already registered');
    }

    if (!resp.ok) {
      return new Error('It was not possible to handle your request');
    }
  } catch (error) {
    return new Error('It was not possible to submit your request');
  }

  return null;
}

import React, { useState } from 'react';
import { Form, redirect, useLoaderData, useNavigation } from 'react-router-dom';

function Settings() {
  const loaderData = useLoaderData() as any;
  const navigation = useNavigation();

  const [allowNether, setAllowNether] = useState(loaderData.allowNether);
  const [generateStructures, setGenerateStructures] = useState(loaderData.generateStructures);
  const [hardcore, setHardcore] = useState(loaderData.hardcore);
  const [hideOnlinePlayers, setHideOnlinePlayers] = useState(loaderData.hideOnlinePlayers);
  const [pvp, setPvP] = useState(loaderData.pvp);
  const [spawnAnimals, setSpawnAnimals] = useState(loaderData.spawnAnimals);
  const [spawnMonsters, setSpawnMonsters] = useState(loaderData.spawnMonsters);
  const [spawnNPCs, setSpawnNPCs] = useState(loaderData.spawnNPCs);

  const {difficulty, gamemode, levelSeed, levelType, maxPlayers, maxWorldSize, motd, spawnProtection} = loaderData;

  const isEntitiesIdle = !navigation.formAction?.endsWith('entities');
  const isGameSettingsIdle = !navigation.formAction?.endsWith('game-settings');
  const isMiscIdle = !navigation.formAction?.endsWith('misc');
  const isWorldGenerationIdle = !navigation.formAction?.endsWith('world-generation');
  const isWorldLimitsIdle = !navigation.formAction?.endsWith('world-limits');

  return (
    <div className="row">
      <div className="d-none d-lg-block col-lg-3">
        <div style={{position: 'sticky', top: '1.5rem'}}>
          <nav id="navbar-example3" className="h-100 flex-column align-items-stretch pe-4">
            <nav className="nav nav-pills flex-column">
              <a className="nav-link py-1" href="#game-settings">Game Settings</a>
              <nav className="nav nav-pills flex-column mb-1">
                <a className="nav-link ms-3 py-1" href="#difficulty">Difficulty</a>
                <a className="nav-link ms-3 py-1" href="#gamemode">Gamemode</a>
                <a className="nav-link ms-3 py-1" href="#hardcore">Hardcore</a>
                <a className="nav-link ms-3 py-1" href="#pvp">PvP</a>
              </nav>
              <a className="nav-link py-1" href="#world-generation">World Generation</a>
              <nav className="nav nav-pills flex-column mb-1">
                <a className="nav-link ms-3 py-1" href="#generate-structures">Generate Structures</a>
                <a className="nav-link ms-3 py-1" href="#level-seed">Level Seed</a>
                <a className="nav-link ms-3 py-1" href="#level-type">Level Type</a>
                <a className="nav-link ms-3 py-1" href="#max-players">Max Players</a>
              </nav>
              <a className="nav-link py-1" href="#entities">Entities</a>
              <nav className="nav nav-pills flex-column mb-1">
                <a className="nav-link ms-3 py-1" href="#spawn-animals">Spawn Animals</a>
                <a className="nav-link ms-3 py-1" href="#spawn-monsters">Spawn Monsters</a>
                <a className="nav-link ms-3 py-1" href="#spawn-npcs">Spawn NPCs</a>
              </nav>
              <a className="nav-link py-1" href="#world-limits">World Limits</a>
              <nav className="nav nav-pills flex-column mb-1">
                <a className="nav-link ms-3 py-1" href="#max-world-size">Max World Size</a>
                <a className="nav-link ms-3 py-1" href="#spawn-protection">Spawn Protection</a>
              </nav>
              <a className="nav-link py-1" href="#misc">Misc</a>
              <nav className="nav nav-pills flex-column mb-1">
                <a className="nav-link ms-3 py-1" href="#allow-nether">Allow Nether</a>
                <a className="nav-link ms-3 py-1" href="#hide-online-players">Hide Online Players</a>
                <a className="nav-link ms-3 py-1" href="#motd">MOTD</a>
              </nav>
            </nav>
          </nav>
        </div>
      </div>
      <div className="col-12 col-lg-9">
        <Form action="game-settings" method="post">
          <div className="card mb-4" id="game-settings">
            <div className="list-group list-group-flush">
              <div className="list-group-item py-3">
                <h5>Game Settings</h5>
                <div style={{fontSize: '.9em', opacity: .6}}>
                  After changes are made to the server properties, the server must
                  be restarted for them to take effect.
                </div>
              </div>

              <div className="list-group-item py-3" id="difficulty">
                <h6>Difficulty</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Defines the difficulty (such as damage dealt by mobs and the way
                  hunger and poison affects players) of the server.
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="difficulty"
                         id="flexRadioDefault1"
                         defaultChecked={difficulty === 'peaceful'}
                         value="peaceful"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Peaceful
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="difficulty"
                         id="flexRadioDefault2"
                         defaultChecked={difficulty === 'easy'}
                         value="easy"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Easy
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="difficulty"
                         id="flexRadioDefault3"
                         defaultChecked={difficulty === 'normal'}
                         value="normal"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault3">
                    Normal
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="difficulty"
                         id="flexRadioDefault4"
                         defaultChecked={difficulty === 'hard'}
                         value="hard"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault4">
                    Hard
                  </label>
                </div>
              </div>

              <div className="list-group-item py-3" id="gamemode">
                <h6>Gamemode</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Defines the mode of gameplay. A game mode dictates how a player
                  may interact with a Minecraft world.
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="gamemode"
                         id="flexRadioDefault5"
                         defaultChecked={gamemode === 'adventure'}
                         value="adventure"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault5">
                    Adventure
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="gamemode"
                         id="flexRadioDefault6"
                         defaultChecked={gamemode === 'creative'}
                         value="creative"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault6">
                    Creative
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="gamemode"
                         id="flexRadioDefault7"
                         defaultChecked={gamemode === 'survival'}
                         value="survival"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault7">
                    Survival
                  </label>
                </div>
              </div>

              <div className="list-group-item py-3" id="hardcore">
                <h6>Hardcore</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  If set to true, server difficulty is ignored and set to hard and
                  players are set to spectator mode if they die.
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input"
                         type="checkbox"
                         role="switch"
                         id="hardcore-input"
                         name="hardcore"
                         defaultChecked={hardcore}
                         onChange={() => setHardcore(!hardcore)}/>
                  <label className="form-check-label" htmlFor="hardcore-input">
                    {hardcore ? 'On' : 'Off'}
                  </label>
                </div>
              </div>

              <div className="list-group-item py-3" id="pvp">
                <h6>PvP</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Enable PvP on the server. Players shooting themselves with
                  arrows receive damage only if PvP is enabled.
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input"
                         type="checkbox"
                         role="switch"
                         id="pvp-input"
                         name="pvp"
                         defaultChecked={pvp}
                         onChange={() => setPvP(!pvp)}/>
                  <label className="form-check-label" htmlFor="pvp-input">
                    {pvp ? 'On' : 'Off'}
                  </label>
                </div>
              </div>
            </div>
            <div className="card-footer py-3 text-end">
              <button className="btn btn-primary btn-sm" disabled={!isGameSettingsIdle} style={{width: '125px'}} type="submit">
                {!isGameSettingsIdle &&
                    <span aria-hidden="true" className="me-2 spinner-border spinner-border-sm" role="status"></span>}
                {isGameSettingsIdle ? 'Save changes' : 'Loading...'}
              </button>
            </div>
          </div>
        </Form>

        <Form action="world-generation" method="post">
          <div className="card mb-4" id="world-generation">
            <div className="list-group list-group-flush">
              <div className="list-group-item py-3">
                <h5>World Generation</h5>
                <div style={{fontSize: '.9em', opacity: .6}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>

              <div className="list-group-item py-3" id="generate-structures">
                <h6>Generate Structures</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Defines whether structures (such as villages) can be generated.
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input"
                         type="checkbox"
                         role="switch"
                         id="generate-structures-input"
                         name="generateStructures"
                         defaultChecked={generateStructures}
                         onChange={() => setGenerateStructures(!generateStructures)}/>
                  <label className="form-check-label" htmlFor="generate-structures-input">
                    {generateStructures ? 'On' : 'Off'}
                  </label>
                </div>
              </div>

              <div className="list-group-item py-3" id="level-seed">
                <h6>Level Seed</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Sets a world seed for the player's world, as in Singleplayer.
                  The world generates with a random seed if left blank.
                </div>
                <input className="form-control w-50"
                       type="text"
                       placeholder=""
                       aria-label="default input example"
                       name="levelSeed"
                       defaultValue={levelSeed}/>
              </div>

              <div className="list-group-item py-3" id="level-type">
                <h6>Level Type</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Determines the world preset that is generated.
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="levelType"
                         id="flexRadioDefault8"
                         defaultChecked={levelType === 'minecraft:normal'}
                         value="minecraft:normal"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault8">
                    Normal
                    <span className="ms-2" style={{fontSize: '.9em', opacity: .6}}>
                    standard world with hills, valleys, water, etc.
                  </span>
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="levelType"
                         id="flexRadioDefault9"
                         defaultChecked={levelType === 'minecraft:flat'}
                         value="minecraft:flat"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault9">
                    Flat
                    <span className="ms-2" style={{fontSize: '.9em', opacity: .6}}>
                    a flat world with no features
                  </span>
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="levelType"
                         id="flexRadioDefault10"
                         defaultChecked={levelType === 'minecraft:large_biomes'}
                         value="minecraft:large_biomes"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault10">
                    Large biomes
                    <span className="ms-2" style={{fontSize: '.9em', opacity: .6}}>
                    same as default but all biomes are larger
                  </span>
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                         type="radio"
                         name="levelType"
                         id="flexRadioDefault11"
                         defaultChecked={levelType === 'minecraft:amplified'}
                         value="minecraft:amplified"/>
                  <label className="form-check-label" htmlFor="flexRadioDefault11">
                    Amplified
                    <span className="ms-2" style={{fontSize: '.9em', opacity: .6}}>
                    same as default but world-generation height limit is increased
                  </span>
                  </label>
                </div>
              </div>

              <div className="list-group-item py-3" id="max-players">
                <h6>Max players</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  The maximum number of players that can play on the server.
                </div>
                <input className="form-control w-50"
                       type="text"
                       placeholder=""
                       aria-label="default input example"
                       name="maxPlayers"
                       defaultValue={maxPlayers}/>
              </div>
            </div>
            <div className="card-footer py-3 text-end">
              <button className="btn btn-primary btn-sm" disabled={!isWorldGenerationIdle} style={{width: '125px'}} type="submit">
                {!isWorldGenerationIdle &&
                    <span aria-hidden="true" className="me-2 spinner-border spinner-border-sm" role="status"></span>}
                {isWorldGenerationIdle ? 'Save changes' : 'Loading...'}
              </button>
            </div>
          </div>
        </Form>

        <Form action="entities" method="post">
          <div className="card mb-4" id="entities">
            <div className="list-group list-group-flush">
              <div className="list-group-item py-3">
                <h5>Entities</h5>
                <div style={{fontSize: '.9em', opacity: .6}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>

              <div className="list-group-item py-3" id="spawn-animals">
                <h6>Spawn Animals</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Determines if animals can spawn.
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input"
                         type="checkbox"
                         role="switch"
                         id="spawn-animals-input"
                         name="spawnAnimals"
                         defaultChecked={spawnAnimals}
                         onChange={() => setSpawnAnimals(!spawnAnimals)}/>
                  <label className="form-check-label" htmlFor="spawn-animals-input">
                    {spawnAnimals ? 'On' : 'Off'}
                  </label>
                </div>
              </div>

              <div className="list-group-item py-3" id="spawn-monsters">
                <h6>Spawn Monsters</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Determines if monsters can spawn.
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input"
                         type="checkbox"
                         role="switch"
                         id="spawn-monsters-input"
                         name="spawnMonsters"
                         defaultChecked={spawnMonsters}
                         onChange={() => setSpawnMonsters(!spawnMonsters)}/>
                  <label className="form-check-label" htmlFor="spawn-monsters-input">
                    {spawnMonsters ? 'On' : 'Off'}
                  </label>
                </div>
              </div>

              <div className="list-group-item py-3" id="spawn-npcs">
                <h6>Spawn NPCs</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Determines whether villagers can spawn.
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input"
                         type="checkbox"
                         role="switch"
                         id="spawn-npcs-input"
                         name="spawnNPCs"
                         defaultChecked={spawnNPCs}
                         onChange={() => setSpawnNPCs(!spawnNPCs)}/>
                  <label className="form-check-label" htmlFor="spawn-npcs-input">
                    {spawnNPCs ? 'On' : 'Off'}
                  </label>
                </div>
              </div>
            </div>
            <div className="card-footer py-3 text-end">
              <button className="btn btn-primary btn-sm" disabled={!isEntitiesIdle} style={{width: '125px'}} type="submit">
                {!isEntitiesIdle &&
                    <span aria-hidden="true" className="me-2 spinner-border spinner-border-sm" role="status"></span>}
                {isEntitiesIdle ? 'Save changes' : 'Loading...'}
              </button>
            </div>
          </div>
        </Form>

        <Form action="world-limits" method="post">
          <div className="card mb-4" id="world-limits">
            <div className="list-group list-group-flush">
              <div className="list-group-item py-3">
                <h5>World Limits</h5>
                <div style={{fontSize: '.9em', opacity: .6}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>

              <div className="list-group-item py-3" id="max-world-size">
                <h6>Max World Size</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  This sets the maximum possible size in blocks, expressed as a
                  radius, that the world border can obtain. Setting the world
                  border bigger causes the commands to complete successfully but
                  the actual border does not move past this block limit.
                </div>
                <input className="form-control w-50"
                       type="text"
                       placeholder=""
                       aria-label="default input example"
                       name="maxWorldSize"
                       defaultValue={maxWorldSize}/>
              </div>

              <div className="list-group-item py-3" id="spawn-protection">
                <h6>Spawn Protection</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Determines the side length of the square spawn protection area
                  as 2x+1. Setting this to 0 disables the spawn protection. A
                  value of 1 protects a 3×3 square centered on the spawn point. 2
                  protects 5×5, 3 protects 7×7, etc.
                </div>
                <input className="form-control w-50"
                       type="text"
                       placeholder=""
                       aria-label="default input example"
                       name="spawnProtection"
                       defaultValue={spawnProtection}/>
              </div>
            </div>
            <div className="card-footer py-3 text-end">
              <button className="btn btn-primary btn-sm" disabled={!isWorldLimitsIdle} style={{width: '125px'}} type="submit">
                {!isWorldLimitsIdle &&
                    <span aria-hidden="true" className="me-2 spinner-border spinner-border-sm" role="status"></span>}
                {isWorldLimitsIdle ? 'Save changes' : 'Loading...'}
              </button>
            </div>
          </div>
        </Form>

        <Form action="misc" method="post">
          <div className="card" id="misc">
            <div className="list-group list-group-flush">
              <div className="list-group-item py-3">
                <h5>Misc</h5>
                <div style={{fontSize: '.9em', opacity: .6}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>

              <div className="list-group-item py-3" id="allow-nether">
                <h6>Allow Nether</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  Allows players to travel to the Nether.
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input"
                         type="checkbox"
                         role="switch"
                         id="allow-nether-input"
                         name="allowNether"
                         defaultChecked={allowNether}
                         onChange={() => setAllowNether(!allowNether)}/>
                  <label className="form-check-label" htmlFor="allow-nether-input">
                    {allowNether ? 'On' : 'Off'}
                  </label>
                </div>
              </div>

              <div className="list-group-item py-3" id="hide-online-players">
                <h6>Hide Online Players</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  If enabled, a player list is not sent on status requests.
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input"
                         type="checkbox"
                         role="switch"
                         id="hide-online-players-input"
                         name="hideOnlinePlayers"
                         defaultChecked={hideOnlinePlayers}
                         onChange={() => setHideOnlinePlayers(!hideOnlinePlayers)}/>
                  <label className="form-check-label" htmlFor="hide-online-players-input">
                    {hideOnlinePlayers ? 'On' : 'Off'}
                  </label>
                </div>
              </div>

              <div className="list-group-item py-3" id="motd">
                <h6>MOTD</h6>
                <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
                  This is the message that is displayed in the server list of the
                  client, below the name.
                </div>
                <input className="form-control w-50"
                       type="text"
                       placeholder=""
                       aria-label="default input example"
                       name="motd"
                       defaultValue={motd}/>
              </div>
            </div>
            <div className="card-footer py-3 text-end">
              <button className="btn btn-primary btn-sm" disabled={!isMiscIdle} style={{width: '125px'}} type="submit">
                {!isMiscIdle &&
                    <span aria-hidden="true" className="me-2 spinner-border spinner-border-sm" role="status"></span>}
                {isMiscIdle ? 'Save changes' : 'Loading...'}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function entitiesAction({params, request}: any): Promise<Response> {
  const formData = await request.formData();
  const data = {
    spawnAnimals: formData.get('spawnAnimals') === 'on',
    spawnMonsters: formData.get('spawnMonsters') === 'on',
    spawnNPCs: formData.get('spawnNPCs') === 'on',
  };

  console.log(data);

  const url = `${apiBaseURL}/bff/${params.serverID}/settings/entities`
  const resp = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'PUT',
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return redirect('..');
}

export async function gameSettingsAction({params, request}: any): Promise<Response> {
  const formData = await request.formData();
  const data = {
    difficulty: formData.get('difficulty'),
    gamemode: formData.get('gamemode'),
    hardcore: formData.get('hardcore') === 'on',
    pvp: formData.get('pvp') === 'on',
  };

  console.log(data);

  const url = `${apiBaseURL}/bff/${params.serverID}/settings/game_settings`
  const resp = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'PUT',
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return redirect('..');
}

export async function miscAction({params, request}: any): Promise<Response> {
  const formData = await request.formData();
  const data = {
    allowNether: formData.get('allowNether') === 'on',
    hideOnlinePlayers: formData.get('hideOnlinePlayers') === 'on',
    motd: formData.get('motd'),
  };

  console.log(data);

  const url = `${apiBaseURL}/bff/${params.serverID}/settings/misc`
  const resp = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'PUT',
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return redirect('..');
}

export async function loader({ params }: any): Promise<any> {
  const url = `${apiBaseURL}/bff/${params.serverID}/settings`
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export async function worldGenerationAction({params, request}: any): Promise<Response> {
  const formData = await request.formData();
  const data = {
    generateStructures: formData.get('generateStructures') === 'on',
    levelSeed: formData.get('levelSeed'),
    levelType: formData.get('levelType'),
    // TODO(revi): ensure we work with valid numbers at this time. Go isn't
    // failing when this is null, it reads it as a 0.
    maxPlayers: Number(formData.get('maxPlayers')),
  };

  console.log(data);

  const url = `${apiBaseURL}/bff/${params.serverID}/settings/world_generation`
  const resp = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'PUT',
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return redirect('..');
}

export async function worldLimitsAction({params, request}: any): Promise<Response> {
  const formData = await request.formData();
  const data = {
    maxWorldSize: Number(formData.get('maxWorldSize')),
    spawnProtection: Number(formData.get('spawnProtection')),
  };

  console.log(data);

  const url = `${apiBaseURL}/bff/${params.serverID}/settings/world_limits`
  const resp = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'PUT',
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return redirect('..');
}

export default Settings;

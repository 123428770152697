import React from 'react';
import { Navigate, Outlet, redirect, useLoaderData, useLocation } from 'react-router-dom';
import Landing from '../components/Landing';
import Header from '../Header';

function Root() {
  const loaderData = useLoaderData() as any;
  const location = useLocation();

  // If the user is accessing the root without a token, then we render the
  // landing page. But if the token is there and the loader returned the result
  // from `/me`, we redirect to the first owned server.
  if (location.pathname === '/') {
    // TODO(revi): ...
    if ('dirt' in loaderData || localStorage.getItem('token') == null) {
      return <Landing diamond={loaderData.diamond}
                      gold={loaderData.gold}
                      iron={loaderData.iron}/>;
    }

    return <Navigate to={`${loaderData.servers[0].id}/overview`}/>;
  }

  // If we're not in the root but the loader wasn't able to fetch `/me`, we
  // redirect the user to the login view.
  if (loaderData == null) {
    return <Navigate to="/login"/>;
  }

  return (
    <>
      <Header darkMode={loaderData.darkMode} email={loaderData.email} servers={loaderData.servers}/>
      <div className="mt-4" style={{flexGrow: 1}}>
        <div className="container">
          <Outlet/>
          <div className="px-3 text-center" style={{
            flexGrow: 0,
            fontSize: '13px',
            marginBottom: '70px',
            marginTop: '70px'
          }}>
            <span style={{opacity: .7}}>
              Need help? Email <a href="mailto:support@varoke.dev">support@varoke.dev</a>.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

// TODO(revi): consider throwing the redirect if the token isn't set.
export async function loader() {
  const darkMode = localStorage.getItem('theme') === 'dark';

  if (darkMode) {
    document.documentElement.setAttribute('data-bs-theme', 'dark');
  }

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const token = localStorage.getItem('token');
  if (!token) {
    // TODO(revi): region should be a parameter.
    const url = `${apiBaseURL}/minecraft/capacity?region=eu`;
    const resp = await fetch(url);

    if (!resp.ok) {
      throw new Response(resp.statusText, {status: resp.status});
    }

    return resp.json();
  }

  const url = `${apiBaseURL}/bff/me`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (resp.status === 401) {
    return redirect('/logout');
  }

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  const jsonResp = await resp.json();
  jsonResp.darkMode = darkMode;

  return jsonResp;
}

export default Root;

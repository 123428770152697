import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useFetcher, useLoaderData } from 'react-router-dom';
import Select from 'react-select';

interface Group {
  color: string;
  displayName: string;
  name: string;
  permissions: string[];
}

interface Permission {
  description: string;
  name: string;
}

interface LoaderData {
  groups: Group[];
  permissions: Permission[];
}

const colors: {[key: string]: string} = {
  dark_aqua: '#00aaaa',
  dark_green: '#00aa00',
  dark_purple: '#aa00aa',
  dark_red: '#aa0000',
  gold: '#ffaa00',
  white: '#ffffff',
  yellow: '#ffff55',
};

function toOptions(permissions: Permission[]) {
  return permissions.map(permission => ({
    label: <><code>{permission.name}</code> - {permission.description}</>,
    value: permission.name,
  }));
}

function Permissions() {
  const fetcher = useFetcher();
  const {groups, permissions} = useLoaderData() as LoaderData;
  const [activeGroup, setActiveGroup] = useState('default');
  const [selectedPermission, setSelectedPermission] = useState<any>(null);

  console.log('selectedPermission', selectedPermission);

  useEffect(() => {
    setSelectedPermission(null);
  }, [fetcher.data]);

  const activePermissions = groups.filter(x => x.name === activeGroup)[0].permissions;

  function description(name: string): string {
    const permission = permissions.find(x => x.name === name);

    if (permission === undefined) {
      return '';
    }

    return permission.description;
  }

  const group = groups.find(x => x.name === activeGroup)!;
  const availablePermissions = permissions.filter(x => !group.permissions.includes(x.name));

  return (
    <div className="row">
      <div className="col-lg-3 mb-4">
        <div className="list-group">
          {groups.map(group => <button aria-current={false}
                                       className={`${group.name === activeGroup ? 'active' : ''} align-items-center d-flex justify-content-between list-group-item list-group-item-action`}
                                       key={group.name}
                                       onClick={e => setActiveGroup(group.name)}
                                       type="button">
            <span><span className="me-2" style={{color: colors[group.color], textShadow: '-1px 0 #dee2e6, 0 1px #dee2e6, 1px 0 #dee2e6, 0 -1px #dee2e6'}}>●</span><span className="fw-medium">{group.displayName}</span> ({group.name})</span>
            <span className="badge bg-primary rounded-pill">{group.permissions.length}</span>
          </button>)}
        </div>
      </div>
      <div className="col-lg-9 mb-4">
        {/*<div className="mb-4 row">*/}
        {/*  <div className="col-6">*/}
        {/*    <Select options={options} />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="row">
          <div className="col">
            <div className="card mb-4">
              <div className="card-header" style={{padding: '12px'}}>
                <fetcher.Form action="add-permission" className="g-3 row" method="post">
                  <div className="col-10">
                    <input name="group" type="hidden" value={activeGroup}/>
                    <Select name="permission"
                            onChange={newValue => setSelectedPermission(newValue)}
                            options={toOptions(availablePermissions)}
                            value={selectedPermission}/>
                  </div>
                  <div className="col-2">
                    <button className="btn btn-primary w-100" disabled={selectedPermission === null} type="submit">Add</button>
                  </div>
                </fetcher.Form>
              </div>
              <div className="list-group list-group-flush">
                {activePermissions.map(permission => <div className="list-group-item" key={permission} style={{paddingBottom: '.25rem', paddingTop: '.25rem'}}>
                  <div className="d-flex">
                    <div className="align-items-center d-flex flex-grow-1" style={{opacity: 1}}>
                      <div>
                    <span>
                        <code>{permission}</code>
                        <span className="ms-2" style={{opacity: .3}}>{description(permission)}</span>
                    </span>
                      </div>
                    </div>
                    <div>
                      <fetcher.Form action="remove-permission" method="post">
                        <input name="group" type="hidden" value={activeGroup}/>
                        <input name="permission" type="hidden" value={permission}/>
                        <button className="btn btn-link me-n3 my-n1" style={{color: 'rgb(34, 34, 34)', opacity: .3}} type="submit">
                          <FontAwesomeIcon icon={faTimes}/>
                        </button>
                      </fetcher.Form>
                    </div>
                  </div>
                </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function addPermissionAction({params, request}: any): Promise<any> {
  const formData = await request.formData();
  const data = {
    group: formData.get('group'),
    permission: formData.get('permission'),
  };

  console.log(data);

  const url = `${apiBaseURL}/bff/${params.serverID}/permissions/${data.group}/${data.permission}`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'POST',
  });

  if (resp.ok) {
    // returns a new object to trigger useEffect on new responses.
    return {};
  }

  throw new Response(resp.statusText, {status: resp.status});
}

export async function loader({ params }: any) {
  const url = `${apiBaseURL}/bff/${params.serverID}/permissions`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export async function removePermissionAction({params, request}: any): Promise<any> {
  const formData = await request.formData();
  const data = {
    group: formData.get('group'),
    permission: formData.get('permission'),
  };

  console.log(data);

  const url = `${apiBaseURL}/bff/${params.serverID}/permissions/${data.group}/${data.permission}`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'DELETE',
  });

  if (resp.ok) {
    // returns a new object to trigger useEffect on new responses.
    return {};
  }

  throw new Response(resp.statusText, {status: resp.status});
}

export default Permissions;

import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

function Plugins() {
  return (
    <div className="row">
      <div className="d-none d-lg-block col-lg-3">
        <div style={{position: 'sticky', top: '1.5rem'}}>
          <div className="list-group">
            <NavLink className="disabled list-group-item list-group-item-action" to="chunky">
              Chunky <span className="opacity-50">v1.3.92</span>
            </NavLink>
            <NavLink className="disabled list-group-item list-group-item-action" to="coreprotect">
              CoreProtect <span className="opacity-50">v22.1.0</span>
            </NavLink>
            <NavLink className="list-group-item list-group-item-action" to="dynmap">
              Dynmap® <span className="opacity-50">v3.6.0</span>
            </NavLink>
            <NavLink className="list-group-item list-group-item-action" to="essentialsx">
              EssentialsX <span className="opacity-50">v2.20.1</span>
            </NavLink>
            <NavLink className="disabled list-group-item list-group-item-action" to="worldedit">
              FastAsyncWorldEdit <span className="opacity-50">v2.7.1</span>
            </NavLink>
            <NavLink className="disabled list-group-item list-group-item-action" to="floodgate">
              Floodgate <span className="opacity-50">v0.0.69</span>
            </NavLink>
            <NavLink className="disabled list-group-item list-group-item-action" to="geyser">
              Geyser <span className="opacity-50">v0.0.264</span>
            </NavLink>
            <NavLink className="disabled list-group-item list-group-item-action" to="luckperms">
              LuckPerms <span className="opacity-50">v5.4.102</span>
            </NavLink>
            <NavLink className="disabled list-group-item list-group-item-action" to="plan">
              Plan <span className="opacity-50">v5.5.2461</span>
            </NavLink>
            <NavLink className="disabled list-group-item list-group-item-action" to="prometheusexporter">
              PrometheusExporter <span className="opacity-50">v2.5.0</span>
            </NavLink>
            <NavLink className="disabled list-group-item list-group-item-action" to="vault">
              Vault <span className="opacity-50">v1.7.3</span>
            </NavLink>
            <NavLink className="disabled list-group-item list-group-item-action" to="worldguard">
              WorldGuard <span className="opacity-50">v7.0.9</span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-9">
        <Outlet />
      </div>
    </div>
  );
}

export async function loader({ params }: any): Promise<any> {
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const url = `${apiBaseURL}/bff/${params.serverID}/settings`
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export default Plugins;

import { Link, useLoaderData } from 'react-router-dom';

export default function StatjarHome(): JSX.Element {
  const [namespaces, error] = useLoaderData() as [Namespace[] | null, Error | null];

  if (error) {
    return <div className="alert alert-danger" role="alert">{error.message}</div>;
  }

  return (
    <div className="container mt-5" style={{maxWidth: '850px'}}>
      <div className="d-flex justify-content-end mb-3">
        <Link style={{textDecoration: 'none'}} to="/statjar/logout">🔓 Log out</Link>
      </div>
      <h1>🏠 Home</h1>
      <h2>📦 Namespaces</h2>
      {namespaces?.length === 0 ? <p>
        You have no namespaces.
      </p> : <ul>
        {namespaces?.map(namespace => <li key={namespace.id}>
          <Link to={`/statjar/namespaces/${namespace.id}`}>
            {namespace.name}
          </Link>
        </li>)}
      </ul>}
      <hr/>
      <small>© 2023 Varoke Gaming Ltd.</small>
    </div>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

interface Namespace {
  id: string,
  userID: string,
  name: string,
}

export async function loader({params}: any): Promise<[Namespace[] | null, Error | null]> {
  const url = `${apiBaseURL}/statjar/v3/home`
  try {
    const resp = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('statjar_session')}`,
      },
    });

    if (resp.status === 401) {
      return [null, new Error("You don't have access to this")];
    }

    if (!resp.ok) {
      return [null, new Error('It was not possible to handle your request')];
    }

    const jsonResp = await resp.json();
    return [jsonResp, null];
  } catch (error) {
    return [null, new Error('It was not possible to submit your request')];
  }
}

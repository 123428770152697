import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import varokeLogo from '../images/varoke-logo.svg';
import varokeLogoDark from '../images/varoke-logo-dark.svg';

const demoToken = 'ebd333a1-840b-447f-b0f1-f6ab622d6dd6';

export function isDemoMode() {
  return localStorage.getItem('token') === demoToken;
}

function Demo() {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      localStorage.setItem('token', demoToken);
      navigate('/');
    }, 3000);

    return () => clearTimeout(timeoutID);
  });

  // TODO(revi): this shouldn't be here.
  const darkMode = localStorage.getItem('theme') === 'dark';

  const logo = darkMode ? varokeLogoDark : varokeLogo;

  return (
    <main>
      <div className="my-5 px-4 py-5 text-center">
        <img alt="Varoke Gaming" className="d-block mb-4 mx-auto" src={logo} style={{height: '32px'}}/>
        <h1 className="display-5 fw-bold text-body-emphasis">Loading panel...</h1>
        <div className="col-lg-6 mx-auto" style={{maxWidth: '600px'}}>
          <p className="lead mb-4">
            Our customers love the panel for its exclusive features and
            customizations. Give it a try, stop settling for less.
          </p>
        </div>
      </div>
    </main>
  );
}

export async function loader(): Promise<Response | null> {
  // TODO(revi): this shouldn't be here.
  const darkMode = localStorage.getItem('theme') === 'dark';

  if (darkMode) {
    document.documentElement.setAttribute('data-bs-theme', 'dark');
  }

  return null;
}

export default Demo;

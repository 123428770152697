import React from 'react';
import LandingFooter from '../components/LandingFooter';
import LandingHeader from '../components/LandingHeader';

function PrivacyPolicy() {
  return (
    <div className="container pt-3" style={{maxWidth: '960px'}}>
      <LandingHeader/>
      <section>
        <h1>Privacy Policy</h1>
      </section>
      <LandingFooter/>
    </div>
  );
}

export default PrivacyPolicy;

import React from 'react';
import { Form, Link, redirect, useNavigation } from 'react-router-dom';
import varokeLogo from '../images/varoke-logo.svg';

function Signup() {
  const navigation = useNavigation();

  const isIdle = navigation.state === 'idle';

  // TODO(revi): use action data to render errors after submitting the form.
  return (
    <div className="container" style={{marginTop: '5vw'}}>
      <div className="justify-content-center row">
        <div className="col-lg-5 col-md-7 col-sm-9 col-xl-4">
          <div className="mb-4 mt-2 text-center">
            <img alt="Varoke Gaming" className="d-inline" src={varokeLogo} style={{height: '32px'}} />
          </div>
          <div className="card">
            <div className="card-body">
              <Form method="post">
                <div className="mb-3">
                  <label className="form-label">First name</label>
                  <input className="form-control" name="firstName" type="text"/>
                </div>
                <div className="mb-3">
                  <label className="form-label">Last name</label>
                  <input className="form-control" name="lastName" type="text"/>
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input className="form-control" name="password" type="password"/>
                </div>
                <div className="d-grid">
                  <button className="btn btn-lg btn-primary" disabled={!isIdle} type="submit">
                    {isIdle && 'Sign up'}
                    {!isIdle && <div className="spinner-border" style={{
                      borderWidth: '.2rem',
                      height: '1.4rem',
                      width: '1.4rem',
                    }}></div>}
                  </button>
                </div>
              </Form>
            </div>
          </div>
          <div className="mb-4 mt-3 text-center">
            Already have an account? <Link to="/login">Log in</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export async function action({params, request}: any) {
  await new Promise(resolve => setTimeout(resolve, 1000));

  const { onboardingToken } = params;

  const data = Object.fromEntries(await request.formData());

  if (data.firstName === '' || data.lastName === '' || data.password === '') {
    return false;
  }

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const resp = await fetch(`${apiBaseURL}/minecraft/onboarding`, {
    body: JSON.stringify({
      first_name: data.firstName,
      last_name: data.lastName,
      onboarding_token: onboardingToken,
      password: data.password,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  // TODO(revi): return something more specific when the token isn't valid.
  if (resp.status === 403) {
    return false;
  }

  if (resp.ok) {
    const jsonResp = await resp.json();
    localStorage.setItem('token', jsonResp.token);
    return redirect('/');
  }

  return false;
}

export default Signup;

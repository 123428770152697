import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form, redirect, useFetcher, useLoaderData } from 'react-router-dom';
import StatusIndicator from '../components/StatusIndicator';
import Endpoints from '../Endpoints';

interface LoaderData {
  activeEntities: number;
  currentIPv4: string;
  currentPort: number;
  dynmap: string;
  id: string;
  loadedChunks: number;
  name: string;
  onlineUsers: number;
  serverAddress: string;
  status: 'DOWN' | 'STARTING' | 'UP';
  uptime: string;
  version: string;
}

function Overview() {
  const fetcher = useFetcher();
  const loaderData = useLoaderData() as LoaderData;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isIdle = fetcher.state === 'idle';

  useEffect(() => {
    if (fetcher.data) {
      handleClose();
    }
  }, [fetcher.data]);

  return (
    <>
      <div className="row">
        <div className="col mb-4">
          <StatusIndicator status={loaderData.status}/>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-4">
          <Endpoints currentIPv4={loaderData.currentIPv4}
                     currentPort={loaderData.currentPort}
                     dynmap={loaderData.dynmap}
                     id={loaderData.id}
                     name={loaderData.name}
                     serverAddress={loaderData.serverAddress}
                     onNameEdit={handleShow}
          />
        </div>
        <div className="col-lg-6 mb-4">
          <Form action="restart" method="post">
            <div className="card">
              <div className="list-group list-group-flush">
                <div className="list-group-item py-3">
                  <h5>Server</h5>
                  <div style={{fontSize: '.9em', opacity: .6}}>
                    Real-time server performance metrics at a glance.
                  </div>
                </div>
                <div className="list-group-item">
                  <div className="d-sm-flex">
                    <div className="flex-grow-1">Active Entities</div>
                    <div style={{opacity: .6}}>{loaderData.activeEntities}</div>
                  </div>
                </div>
                <div className="list-group-item">
                  <div className="d-sm-flex">
                    <div className="flex-grow-1">Loaded Chunks</div>
                    <div style={{opacity: .6}}>{loaderData.loadedChunks}</div>
                  </div>
                </div>
                <div className="list-group-item">
                  <div className="d-sm-flex">
                    <div className="flex-grow-1">Online Users</div>
                    <div style={{opacity: .6}}>{loaderData.onlineUsers}</div>
                  </div>
                </div>
                <div className="list-group-item">
                  <div className="d-sm-flex">
                    <div className="flex-grow-1">Uptime</div>
                    <div style={{opacity: .6}}>{loaderData.uptime}</div>
                  </div>
                </div>
                <div className="list-group-item">
                  <div className="d-sm-flex">
                    <div className="flex-grow-1">Version</div>
                    <div style={{opacity: .6}}>{loaderData.version}</div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Modal onHide={handleClose} show={show}>
        <fetcher.Form action="rename" method="post">
          <Modal.Header closeButton>
            <Modal.Title>Rename server</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="form-label" htmlFor="name">Name</label>
              <input className="form-control" defaultValue={loaderData.name} id="name" name="name" type="text" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={!isIdle} onClick={handleClose} variant="secondary">Close</Button>
            <Button disabled={!isIdle} type="submit" variant="primary">Save Changes</Button>
          </Modal.Footer>
        </fetcher.Form>
      </Modal>
    </>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function loader({ params }: any) {
  const url = `${apiBaseURL}/bff/${params.serverID}/overview`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export async function renameServerAction({params, request}: any): Promise<any> {
  const formData = await request.formData();
  const data = {
    name: formData.get('name'),
  };

  console.log(data);

  const url = `${apiBaseURL}/bff/${params.serverID}/overview/rename`;
  const resp = await fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'PUT',
  });

  if (resp.ok) {
    // returns a new object to trigger useEffect on new responses.
    return {};
  }

  throw new Response(resp.statusText, {status: resp.status});
}

export async function restartAction({params}: any): Promise<Response> {
  const url = `${apiBaseURL}/bff/${params.serverID}/restart`
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'POST',
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return redirect('..');
}

export default Overview;

import { Application } from '../types/Statjar';

export async function loader({params}: any): Promise<Application> {
  const cdnBaseURL = 'https://revicdn.com/statjar';

  const resp = await fetch(`${cdnBaseURL}/${params.snapshotUUID}.json`);
  if (!resp.ok) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

import React from 'react';
import { Form, useNavigation } from 'react-router-dom';
import { isDemoMode } from '../routes/Demo';

interface Props {
  status: 'DOWN' | 'STARTING' | 'UP'
}

const statuses = {
  'DOWN': {
    color: 'rgb(220, 53, 69)',
    subtitle: 'During the private beta, servers can go offline while we perform maintenance operations.',
    title: 'The server is not running.',
  },
  'STARTING': {
    color: 'rgb(255, 193, 7)',
    subtitle: 'Starting a server is a CPU-intensive task, give it a minute or two to become available.',
    title: 'The server is starting.',
  },
  'UP': {
    color: 'rgb(25, 135, 84)',
    subtitle: 'We will let you know if it needs to be restarted.',
    title: 'The server is running.',
  },
}

function StatusIndicator({status}: Props) {
  const navigation = useNavigation();

  const isDemo = isDemoMode();

  const data = statuses[status];
  const isRestartIdle = !navigation.formAction?.endsWith('restart');

  return (
    <Form action="restart" method="post">
      <div className="card">
        <div className="card-body">
          <div className="d-flex">
            <div className="align-items-center d-flex me-3">
              <span style={{color: data.color}}>●</span>
            </div>
            <div className="align-items-center d-flex flex-grow-1">
              <div>
                <div>{data.title}</div>
                <div className="d-none d-md-block mt-1 mt-md-0" style={{fontSize: '.85em', opacity: .7}}>
                  {data.subtitle}
                </div>
              </div>
            </div>
            <div className="align-items-center d-flex ms-1">
              <button className="btn btn-outline-primary"
                      disabled={!isRestartIdle || status === 'STARTING' || isDemo}
                      type="submit">
                Restart server
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default StatusIndicator;

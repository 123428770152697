import React from 'react';
import LandingFooter from '../components/LandingFooter';
import LandingHeader from '../components/LandingHeader';

function TermsOfService() {
  return (
    <div className="container pt-3" style={{maxWidth: '960px'}}>
      <LandingHeader/>
      <section>
        <h1>Terms of Service</h1>
        <p>
          These Terms of Service, together with our <a href="/privacy-policy">Privacy Policy</a> and <a href="/acceptable-use-policy">Acceptable Use Policy</a>,
          collectively constitute the legal terms on which agreements between Varoke Gaming, a trade name of Varoke
          Gaming Ltd., and its Customers are based, and are governed by and should be construed in accordance with the
          laws of the United Kingdom.
        </p>
        <p>
          We reserve the right to amend any terms within these documents at any time without prior notice.
        </p>
        <p>
          By agreeing to these Terms of Service, you also agree to the <a href="https://minecraft.net/eula">Minecraft End User License Agreement</a>,
          and those for any other games or software hosted on our platform, by
          authorizing Varoke Gaming to accept this agreement on your behalf.
        </p>
        <h2>Definitions</h2>
        <p>
          "Terms" refers collectively to any or all of the terms within our Terms of Service, <a href="/acceptable-use-policy">Acceptable Use Policy</a> and <a href="/privacy-policy">Privacy Policy</a> documents.
        </p>
        <p>
          A "Visitor" is someone who merely browses our website, but has not registered as a Customer.
        </p>
        <p>
          A "Customer" is an individual that has registered with us to use our Services.
        </p>
        <p>
          "Personally Identifiable Information" (PII), a term used in the context of data privacy referring to any data
          that can be used to identify a specific individual e.g. social security number, email address or phone number,
          and in technology use cases can include IP addresses, user IDs, social media posts, or digital images.
          Geolocation, biometric, and behavioral data may also be classified as PII.
        </p>
        <p>
          "Our Services" refers to any or all of our websites at varokegaming.com, its subdomains, associated domains,
          and our associated hosting services.
        </p>
        <p>
          A "User" is a collective identifier that refers to either a Visitor or a Customer.
        </p>
        <p>
          The terms "us" or "we" or "our" refers to Varoke Gaming, a trade name of Varoke Gaming Ltd., the owner of Our
          Services.
        </p>
        <p>
          All text, software, information, graphics, audio, video, and data that can be viewed, accessed or is offered
          through Our Services is collectively referred to as our "Content".
        </p>
        <p>
          By accessing Our Services, you agree to be bound by our Terms, all applicable laws and regulations. All
          content within Our Services is protected by applicable copyright and trademark laws.
        </p>
        <h2>Use license</h2>
        <p>
          Permission is granted to temporarily download one copy of the materials (information or software) on Varoke
          Gaming's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a
          transfer of title, and under this license you may not:
        </p>
        <ul>
          <li>
            modify or copy the materials;
          </li>
          <li>
            use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
          </li>
          <li>
            attempt to decompile or reverse engineer any software contained on Varoke Gaming's website;
          </li>
          <li>
            remove any copyright or other proprietary notations from the materials; or
          </li>
          <li>
            transfer the materials to another person or replicate the materials on any other server.
          </li>
        </ul>
        <p>
          Any abuse or violation of this license may result in your access to Our Services being automatically
          terminated by us without notice.
        </p>
        <h2>Your account</h2>
        <p>
          When using any of Our Services, you are accountable for maintaining the security and confidentiality of your
          account and password/authentication methods, and are responsible for all activities that take place under your
          account. This includes the disclosure to any third party, other than Varoke Gaming or its payment service
          providers, any Personally Identifiable Information that you obtain via the use of Our Services.
        </p>
        <p>
          By using Our Services, you acknowledge and agree that you are at least 18 years of age. If you are under 18
          years of age but are at least 13 years old, you may only use Our Services with the consent of your parent or
          legal guardian.
        </p>
        <p>
          You agree to maintain up-to-date account and payment information to ensure Varoke Gaming can provide you with
          a reliable and responsive service.
        </p>
        <p>
          Should you encounter any issues with your Varoke Gaming account or Our Services at any time, please <a href="mailto:support@varoke.dev">get in touch</a> so
          we can address it in a timely manner.
        </p>
        <h2>Charges, payments & refunds</h2>
        <p>
          New Customers should register for their Varoke Gaming account on our website and receive their account
          confirmation email before purchasing one or more of Our Services. All newly purchased services are invoiced
          immediately in accordance with the monthly or quarterly billing option selected. The invoice must then be paid
          in full before the service(s) are provisioned.
        </p>
        <p>
          For existing Customers with recurring monthly and quarterly Minecraft hosting services, the timeline for
          invoices and payment is as follows:
        </p>
        <ul>
          <li>
            7 days before the due date, invoices for monthly and quarterly billed services are issued
          </li>
          <li>
            3 days before the due date, if an invoice is unpaid, a payment reminder email is issued
          </li>
          <li>
            On the due date, if an invoice is unpaid, the Customer is given 3 day's grace to make full payment
          </li>
          <li>
            3 days after the due date, if an invoice remains unpaid, the Customer will be notified by email and the
            relevant service will be suspended. When this happens, the service will cease to operate but can be
            reactivated without any loss of data once full invoice payment has been received
          </li>
          <li>
            7 days after the due date, if an invoice remains unpaid, the Customer will be notified by email and the
            relevant service will be terminated
          </li>
        </ul>
        <p>
          When a service is terminated for non-payment of an invoice (or for any other reason), any data associated with
          that service is automatically deleted from our systems and cannot be recovered. Your Varoke Gaming account
          will not be removed from our systems.
        </p>
        <p>
          Varoke Gaming offers a 72-hour, no-questions-asked refund policy for Minecraft hosting services, referred to
          on our website as a "Money-Back Guarantee". We reserve the right to deny a refund request if we believe
          fraudulent activity may be involved, and we will only ever issue one refund request per Customer. We will not
          offer refunds after this 72-hour period, unless it forms part of a promotional offer. To request a refund,
          please <a href="mailto:support@varoke.dev">contact us</a> as soon as possible.
        </p>
        <p>
          Initiating a dispute or chargeback via any payment gateway will result in immediate termination of your
          account including any services purchased, and a permanent ban from using Our Services at any point in the
          future.
        </p>
        <p>
          "Free credit" on partner platforms of Varoke Gaming, offered with some plans, applies to paying Customers
          only. Free trial Customers or Customers partaking in a promotional deal may be excluded from this offer.
        </p>
        <h2>Service Level Agreement (SLA)</h2>
        <p>
          Varoke Gaming guarantees a network and hardware uptime of 99.95% in any given month. In the unlikely event
          that the uptime of your server falls below 99.95%, you may request to be compensated by <a href="mailto:support@varoke.dev">getting in touch</a> with
          us. We will then investigate the circumstances and duration of the downtime.
        </p>
        <p>
          Where the downtime was entirely due to a problem with Our Services and was beyond your control, at our
          discretion we may agree to credit your Varoke Gaming account with an amount equal to the cost of that
          downtime. Any credit held in your Varoke Gaming account can be used to make future purchases of Our Services.
        </p>
        <p>
          Note that any "free" services are provided without warranty and are not eligible for SLA compensation. Varoke
          Gaming is not liable for any data loss occurring on services provided at no cost to the end user.
        </p>
        <h2>Cancellation of services</h2>
        <p>
          When a Customer wishes to cancel one or more of Our Services, they must notify us of this using the Request
          Cancellation option from within our control panel. The cancellation options are as follows:
        </p>
        <ul>
          <li>
            Immediately - the selected service is terminated within 24 hours of receipt of the cancellation request. No
            refunds will be provided for any of the billing period that is outstanding
          </li>
          <li>
            Next Due Date - the selected service is terminated on the next invoice due date
          </li>
        </ul>
        <p>
          In both cases, all data associated with the service will be deleted automatically on the relevant date with no
          option for recovery. Where a Customer wishes to retain any of their data, it is their responsibility to save
          it to a safe location outside their server before the service termination date.
        </p>
        <p>
          Customers are responsible for canceling any recurring payment subscriptions for Our Services that they have
          set up with a payment gateway or provider such as PayPal upon service termination.
        </p>
        <h2>Links to third-party websites</h2>
        <p>
          Our Services may contain links to third party websites. These links are provided solely as a convenience to
          you and do not create or imply any affiliation or recommendation between Varoke Gaming and the third party,
          unless specifically stated on the Varoke Gaming website.
        </p>
      </section>
      <LandingFooter/>
    </div>
  );
}

export default TermsOfService;

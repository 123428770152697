import React, { useEffect } from 'react';
import {
  Form,
  Link,
  redirect,
  useActionData, useNavigate,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';
import varokeLogo from '../images/varoke-logo.svg';
import varokeLogoDark from '../images/varoke-logo-dark.svg';

function Login() {
  const actionData = useActionData();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  useEffect(() => {
    if (token !== null) {
      localStorage.setItem('token', token);
      navigate('/');
    }
  }, [navigate, token]);

  const isIdle = navigation.state === 'idle';

  // TODO(revi): this shouldn't be here.
  const darkMode = localStorage.getItem('theme') === 'dark';

  const logo = darkMode ? varokeLogoDark : varokeLogo;

  return (
    <div className="container" style={{marginTop: '5vw'}}>
      <div className="justify-content-center row">
        <div className="col-lg-5 col-md-7 col-sm-9 col-xl-4">
          <div className="mb-4 mt-2 text-center">
            <img alt="Varoke Gaming" className="d-inline" src={logo} style={{height: '32px'}} />
          </div>
          <div className="card">
            <div className="card-body">
              <Form method="post">
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input className="form-control" name="email" type="email"/>
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input className={'form-control' + (actionData === false ? ' is-invalid' : '')}
                         name="password"
                         type="password"/>
                  {actionData === false && <div className='invalid-feedback'>Invalid email/password combination.</div>}
                  <div className="mt-1 text-end" style={{fontSize: '.8em'}}>
                    <Link to="/reset-password">Forgot password?</Link>
                  </div>
                </div>
                <div className="d-grid">
                  <button className="btn btn-lg btn-primary" disabled={!isIdle} type="submit">
                    {isIdle && 'Log in'}
                    {!isIdle && <div className="spinner-border" style={{
                      borderWidth: '.2rem',
                      height: '1.4rem',
                      width: '1.4rem',
                    }}></div>}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export async function action({params, request}: any) {
  await new Promise(resolve => setTimeout(resolve, 1000));

  const data = Object.fromEntries(await request.formData());

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const resp = await fetch(`${apiBaseURL}/minecraft/login`, {
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  if (resp.status === 401) {
    return false;
  }

  if (resp.ok) {
    const jsonResp = await resp.json();
    localStorage.setItem('token', jsonResp.token);
    return redirect('/');
  }

  return false;
}

export async function loader(): Promise<Response | null> {
  // TODO(revi): this shouldn't be here.
  const darkMode = localStorage.getItem('theme') === 'dark';

  if (darkMode) {
    document.documentElement.setAttribute('data-bs-theme', 'dark');
  }

  const token = localStorage.getItem('token');

  if (token != null) {
    return redirect('/');
  }

  return null;
}

export default Login;

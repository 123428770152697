import { faCopy, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  currentIPv4: string;
  currentPort: number;
  dynmap: string;
  id: string;
  name: string;
  serverAddress: string;
  onNameEdit: any;
}

function Endpoints({currentIPv4, currentPort, dynmap, id, name, serverAddress, onNameEdit}: Props) {
  return (
    <div className="card">
      <div className="list-group list-group-flush">
        <div className="list-group-item py-3">
          <h5>Endpoints</h5>
          <div style={{fontSize: '.9em', opacity: .6}}>
            Set up Minecraft using the endpoints below.
          </div>
        </div>
        <div className="list-group-item">
          <div className="d-sm-flex">
            <div className="flex-grow-1">ID</div>
            <div style={{opacity: .6}}>{id}</div>
          </div>
        </div>
        <div className="list-group-item">
          <div className="d-sm-flex">
            <div className="flex-grow-1">Name</div>
            <div>
              <span className="me-3" style={{opacity: .6}}>{name}</span>
              <span className="text-primary" onClick={onNameEdit} style={{cursor: 'pointer'}}>
                <FontAwesomeIcon icon={faPenToSquare}/>
              </span>
            </div>
          </div>
        </div>
        <div className="list-group-item">
          <div className="d-sm-flex">
            <div className="flex-grow-1">Server Address</div>
            <div>
              <span className="me-3" style={{opacity: .6}}>{serverAddress}</span>
              <span className="text-primary" onClick={() => navigator.clipboard.writeText(serverAddress)} style={{cursor: 'pointer'}}>
                <FontAwesomeIcon icon={faCopy}/>
              </span>
            </div>
          </div>
        </div>
        <div className="list-group-item">
          <div className="d-sm-flex">
            <div className="flex-grow-1">Dynmap&reg;</div>
            <div>
              <a className="text-decoration-none" href={dynmap} rel="noreferrer" target="_blank">
                {dynmap.replace('https://', '')}
              </a>
            </div>
          </div>
        </div>
        <div className="list-group-item">
          <div className="d-sm-flex">
            <div className="flex-grow-1">Current IPv4</div>
            <div style={{opacity: .6}}>{currentIPv4}</div>
          </div>
        </div>
        <div className="list-group-item">
          <div className="d-sm-flex">
            <div className="flex-grow-1">Current Port</div>
            <div style={{opacity: .6}}>{currentPort}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Endpoints;

import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link, useLocation, useParams } from 'react-router-dom';
import Navbar1 from './components/Navbar1';
import Navbar2 from './components/Navbar2';
import varokeLogo from './images/varoke-logo.svg';
import varokeLogoDark from './images/varoke-logo-dark.svg';

interface Props {
  darkMode: boolean;
  email: string;
  servers: Server[];
}

interface Server {
  id: string;
  environment: 'prod' | 'test';
  name: string;
  region: 'eu' | 'na';
}

function serverName(server: Server) {
  const name = `${server.name}`;

  if (server.environment === 'test') {
    return `${name} #${server.id} (test)`;
  }

  return `${name} #${server.id}`;
}

function serverNameTitle(server: Server) {
  const name = `${server.name}`;

  return <span>{name} <span className="text-secondary">#{server.id}</span></span>;
}

function Header({darkMode, email, servers}: Props) {
  const location = useLocation();
  const { serverID: currentServerID } = useParams();

  const currentServer = servers.find(server => server.id === currentServerID)!;

  const serversInEurope = servers.filter(server => server.region === 'eu');
  const serversInNorthAmerica = servers.filter(server => server.region === 'na');

  const background = darkMode ? 'rgb(52, 58, 64)' : 'rgb(254, 254, 254)';
  const borderBottom = darkMode ? '1px solid #495057' : '1px solid #dee2e6';
  const variant = darkMode ? 'dark' : 'light';
  const logo = darkMode ? varokeLogoDark : varokeLogo;
  const themeText = darkMode ? 'Light mode' : 'Dark mode';

  function toggleTheme() {
    const theme = darkMode ? 'light' : 'dark';
    localStorage.setItem('theme', theme);
    window.location.reload();
  }

  return (
    <>
      {email === 'demo@varoke.dev' && <div className="alert alert-primary py-2 text-center" role="alert" style={{borderRadius: 0, marginBottom: 0}}>
          <span className="d-none d-lg-inline">
              This working demo lets you explore the many features of our panel, but
              some of them may be disabled for security reasons.<br/>
          </span>
          Ready to take advantage of a 25% discount? Just <Link className="alert-link" to="/logout">go back</Link> and
          use the discount code <code>PANELDEMO</code>.
      </div>}
      <Navbar1 darkMode={darkMode} email={email} servers={servers} />
      <Navbar2 />
      <div className="d-none pt-4" style={{background: background, borderBottom: borderBottom}}>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="d-flex mb-4">
                <div className="flex-grow-1">
                  <div className="d-flex">
                    <Link className="me-4" to="/">
                      <img alt="Varoke Gaming" className="d-inline" src={logo} style={{height: '32px', marginTop: '3px'}} />
                    </Link>
                    <DropdownButton title={serverNameTitle(currentServer)} variant={variant}>
                      {serversInEurope.length >= 1 && <Dropdown.Header>Europe</Dropdown.Header>}
                      {serversInEurope.map(server => <Dropdown.Item active={server.id === currentServerID}
                                                                    as={Link}
                                                                    key={server.id}
                                                                    to={location.pathname.replace(currentServerID!, server.id)}>{serverName(server)}</Dropdown.Item>)}
                      {serversInNorthAmerica.length >= 1 && <Dropdown.Header>North America</Dropdown.Header>}
                      {serversInNorthAmerica.map(server => <Dropdown.Item active={server.id === currentServerID}
                                                                          as={Link}
                                                                          key={server.id}
                                                                          to={location.pathname.replace(currentServerID!, server.id)}>{serverName(server)}</Dropdown.Item>)}
                      <Dropdown.Divider/>
                      <Dropdown.Item href="#">New</Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
                <div>
                  <DropdownButton title={email} variant={variant}>
                    <Dropdown.Item href="#">Account</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={toggleTheme}>{themeText}</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/logout">Log out</Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;

import React, { useState } from 'react';
import { Form, useLoaderData, useNavigation } from 'react-router-dom';

function Dynmap() {
  const loaderData = useLoaderData() as any;
  const navigation = useNavigation();

  const [hardcore, setHardcore] = useState(loaderData.hardcore);
  const [pvp, setPvP] = useState(loaderData.pvp);

  const {difficulty, gamemode} = loaderData;

  const isGameSettingsIdle = !navigation.formAction?.endsWith('game-settings');

  return (
    <Form action="game-settings" method="post">
      <div className="card mb-4" id="game-settings">
        <div className="list-group list-group-flush">
          <div className="list-group-item py-3">
            <h5>Dynmap®</h5>
            <div style={{fontSize: '.9em', opacity: .6}}>
              After changes are made to the server properties, the server must
              be restarted for them to take effect.
            </div>
          </div>

          <div className="list-group-item py-3" id="difficulty">
            <h6>Difficulty</h6>
            <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
              Defines the difficulty (such as damage dealt by mobs and the way
              hunger and poison affects players) of the server.
            </div>
            <div className="form-check">
              <input className="form-check-input"
                     type="radio"
                     name="difficulty"
                     id="flexRadioDefault1"
                     defaultChecked={difficulty === 'peaceful'}
                     value="peaceful"/>
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Peaceful
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input"
                     type="radio"
                     name="difficulty"
                     id="flexRadioDefault2"
                     defaultChecked={difficulty === 'easy'}
                     value="easy"/>
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Easy
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input"
                     type="radio"
                     name="difficulty"
                     id="flexRadioDefault3"
                     defaultChecked={difficulty === 'normal'}
                     value="normal"/>
              <label className="form-check-label" htmlFor="flexRadioDefault3">
                Normal
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input"
                     type="radio"
                     name="difficulty"
                     id="flexRadioDefault4"
                     defaultChecked={difficulty === 'hard'}
                     value="hard"/>
              <label className="form-check-label" htmlFor="flexRadioDefault4">
                Hard
              </label>
            </div>
          </div>

          <div className="list-group-item py-3" id="gamemode">
            <h6>Gamemode</h6>
            <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
              Defines the mode of gameplay. A game mode dictates how a player
              may interact with a Minecraft world.
            </div>
            <div className="form-check">
              <input className="form-check-input"
                     type="radio"
                     name="gamemode"
                     id="flexRadioDefault5"
                     defaultChecked={gamemode === 'adventure'}
                     value="adventure"/>
              <label className="form-check-label" htmlFor="flexRadioDefault5">
                Adventure
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input"
                     type="radio"
                     name="gamemode"
                     id="flexRadioDefault6"
                     defaultChecked={gamemode === 'creative'}
                     value="creative"/>
              <label className="form-check-label" htmlFor="flexRadioDefault6">
                Creative
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input"
                     type="radio"
                     name="gamemode"
                     id="flexRadioDefault7"
                     defaultChecked={gamemode === 'survival'}
                     value="survival"/>
              <label className="form-check-label" htmlFor="flexRadioDefault7">
                Survival
              </label>
            </div>
          </div>

          <div className="list-group-item py-3" id="hardcore">
            <h6>Hardcore</h6>
            <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
              If set to true, server difficulty is ignored and set to hard and
              players are set to spectator mode if they die.
            </div>
            <div className="form-check form-switch">
              <input className="form-check-input"
                     type="checkbox"
                     role="switch"
                     id="hardcore-input"
                     name="hardcore"
                     defaultChecked={hardcore}
                     onChange={() => setHardcore(!hardcore)}/>
              <label className="form-check-label" htmlFor="hardcore-input">
                {hardcore ? 'On' : 'Off'}
              </label>
            </div>
          </div>

          <div className="list-group-item py-3" id="pvp">
            <h6>PvP</h6>
            <div className="mb-2" style={{fontSize: '.9em', opacity: .6}}>
              Enable PvP on the server. Players shooting themselves with
              arrows receive damage only if PvP is enabled.
            </div>
            <div className="form-check form-switch">
              <input className="form-check-input"
                     type="checkbox"
                     role="switch"
                     id="pvp-input"
                     name="pvp"
                     defaultChecked={pvp}
                     onChange={() => setPvP(!pvp)}/>
              <label className="form-check-label" htmlFor="pvp-input">
                {pvp ? 'On' : 'Off'}
              </label>
            </div>
          </div>
        </div>
        <div className="card-footer py-3 text-end">
          <button className="btn btn-primary btn-sm" disabled={!isGameSettingsIdle} style={{width: '125px'}} type="submit">
            {!isGameSettingsIdle &&
                <span aria-hidden="true" className="me-2 spinner-border spinner-border-sm" role="status"></span>}
            {isGameSettingsIdle ? 'Save changes' : 'Loading...'}
          </button>
        </div>
      </div>
    </Form>
  );
}

export async function loader({ params }: any): Promise<any> {
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const url = `${apiBaseURL}/bff/${params.serverID}/settings`
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export default Dynmap;
